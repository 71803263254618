import React from 'react';
import './sideBar.css';
import { RxAvatar } from 'react-icons/rx';

export default function UserAvatar() {

    const userInfo = localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo'))
        : null;

    // Vérification si l'utilisateur est connecté
    const isLoggedIn = userInfo && userInfo.token;

    return (
        <a className='avatar' href="/profile">
            {isLoggedIn && (<RxAvatar size="30px" />)}
        </a>
    );
}


