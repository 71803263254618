import React from 'react';
import ScrollToTopButton from './ScrollToTopBtn';
import './secret.css';

const SecretPage = () => {



    return (
        <div className="story-details">
            <div >

                <ScrollToTopButton />

                <p className='txtStory'>
                    <h2>Le lapin et son copain</h2>
                    Il était une fois, dans une forêt enchantée, un petit lapin du nom de Léo.
                    <br />
                    <br />
                    <img className='lapinImg jello-vertical' src='../../lapin005.jpeg' alt='lapin005' />
                    <br />
                    <br />
                    Léo adorait explorer les bois, découvrir de nouvelles fleurs et rencontrer de nouveaux amis.
                    <br />
                    Un jour, alors qu'il se promenait près d'un ruisseau scintillant, il entendit un doux gazouillis.
                    <br />
                    Il se rapprocha doucement et découvrit un oiseau bleu tout mignon qui chantait une douce mélodie.
                </p>
                <p className='txtStory'>
                    <br />
                    <br />
                    <img className='lapinImg jello-vertical' src='../../lapin002.jpeg' alt='lapin002' />
                    <br />
                    <br />
                    Léo et l'oiseau bleu devinrent rapidement les meilleurs amis.
                    <br />
                    Ils passaient leurs journées à jouer et à explorer ensemble.
                    <br />
                    Ils grimpaient aux arbres, sautaient par-dessus les petits ruisseaux et se cachaient parmi les fleurs colorées.

                    <br />
                </p>
                <p className='txtStory'>
                    <br />
                    <img className='lapinImg jello-vertical' src='../../lapin007.jpeg' alt='lapin007' />
                    <br />
                    <br />

                    <br />
                    Chaque soir, avant le coucher du soleil, l'oiseau bleu commençait à chanter sa mélodie apaisante.
                    <br />
                    Sous le doux chant de l'oiseau, Léo se sentait calme et heureux.
                    <br />
                    Ses yeux de lapin se fermaient lentement, tandis qu'il écoutait la mélodie qui l'entourait comme une douce couverture.
                    <br />
                    L'oiseau bleu chantait des histoires de rêves merveilleux et d'aventures magiques.
                    <br />
                </p>
                <p className='txtStory'>
                    <br />
                    <br />
                    <img className='lapinImg jello-vertical' src='../../lapin008.jpeg' alt='lapin008' />
                    <br />
                    <br />
                    Léo s'imaginait courir avec des licornes dans des prairies remplies de fleurs scintillantes et de lucioles dansantes.
                    <br />
                    Au fil du temps, Léo commença à s'endormir plus rapidement en écoutant le chant de son ami l'oiseau bleu.
                    <br />
                    Chaque nuit, ils se retrouvaient dans leurs rêves pour de nouvelles aventures fantastiques.
                    <br />
                    Léo voyageait à dos de papillon géant, visitait des châteaux faits de nuages moelleux et explorait des océans remplis d'étoiles de mer lumineuses.
                    <br />
                    Et ainsi, chaque soir, l'histoire du lapin Léo et de l'oiseau bleu continuait, apportant douceur et réconfort au bébé endormi.
                    <br />
                    Les étoiles brillaient dans le ciel nocturne pendant que les amis s'immergeaient dans un monde de rêves magiques et paisibles.
                    <br />
                    <br />
                </p>
                <p className='txtStory'>
                    <br />
                    <br />
                    <img className='lapinImg jello-vertical' src='../../lapin009.jpeg' alt='lapin009' />
                    <br />
                    <br />

                    Bonne nuit, petit bébé.
                    <br />
                    Que ton sommeil soit rempli de rêves merveilleux et de doux souvenirs.
                </p>

                ____________________

            </div >
        </div>
    );
};

export default SecretPage;
