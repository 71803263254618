import React, { useState, useEffect } from 'react';
import './CoursesList.css';

function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 300) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', handleScroll);
      }
  
      return () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('scroll', handleScroll);
        }
      };
    }, []); // Make sure to pass an empty array as the dependency list
  
    const scrollToTop = () => {
      if (typeof window !== 'undefined') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };
  
    return (
      <button
        className={`scroll-to-top-button ${isVisible ? 'show' : ''}`}
        onClick={scrollToTop}
      >
        Back to Top
      </button>
    );
  }
  
  export default ScrollToTopButton;