import React from 'react';

export default function LegalsPageBreakTimeBuddy() {
    return (
        <div>
            <h2>Legal Mentions</h2>
            <img
                className="fit-picture"
                src="../../wip-bg.png"
                alt="WIP LOGO"
            />
            <p className='legalsTxt'>Mentions légales

              
                **Mentions Légales - Break Time Buddy**

                <br />
                **Objet de l'Extension :**
                <br />
                Break Time Buddy est une extension Chrome développée dans le but d'aider les utilisateurs à gérer leur temps d'écran en leur offrant des rappels pour prendre des pauses régulières lors de l'utilisation de leur navigateur.
                <br />
                **Collecte de Données :**
                <br />
                Break Time Buddy ne collecte aucune donnée personnelle ou sensible des utilisateurs. Aucune information d'identification personnelle n'est enregistrée, stockée ou transmise par l'extension.
                <br />
                **Utilisation des Données :**
                <br />
                Toutes les données générées par l'utilisation de Break Time Buddy restent sur l'appareil de l'utilisateur et ne sont pas accessibles à l'extension ou à son propriétaire. Aucune donnée n'est partagée avec des tiers.
                <br />
                **Responsabilité :**
                <br />
                L'utilisation de l'extension Break Time Buddy relève de la responsabilité de l'utilisateur. Le propriétaire de l'extension ne peut être tenu responsable des conséquences de l'utilisation de l'extension.
                <br />
                **Modifications des Mentions Légales :**
                <br />
                Le propriétaire se réserve le droit de modifier les présentes mentions légales à tout moment. Les utilisateurs seront informés de toute modification via une mise à jour de l'extension.
                <br />
                **Droit Applicable :**
                <br />
                Les présentes mentions légales sont régies par le droit applicable en France.
                <br />
                ---
                <br />
                
                Merci d'utiliser notre application web !
                <br />
                **Propriétaire de l'Extension :**
                <br />
                @ZI-C17
            </p>
            <br />

        </div>
    );
}