import React from 'react';
import { useParams } from 'react-router-dom';
import ScrollToTopButton from './ScrollToTopBtn';
import BackToPage from './BackToPage';

const AgileTest = () => {



    return (
        <div>
            <BackToPage />
            <div className="course-details">

                <h2><img className='robotIcon jello-vertical' src='../../robot002Tiny.png' alt='robot' /> Become an AGILE Tester <img className='robotIcon jello-vertical' src='../../robot002Tiny.png' alt='robot' /></h2>
                <p className="description"></p>
                <div className="meta">
                    <img className='robotIcon jello-vertical' src='../../kawaii001Tiny.png' alt='robot' />
                    <div className="author">Auteur: JC Jamet</div>
                    <div className="duration">Reading time: 30 min </div>
                    <img className='robotIcon jello-vertical' src='../../star001.png' alt='robot' />
                </div>
                <ScrollToTopButton />

                {/* Table of Contents */}

                <div className="table-of-contents">
                    <h3>Table of Contents</h3>
                    <ul>
                        <li><a href="#chapter-1">Chapter 1: Understanding Agile Principles</a></li>
                        <li><a href="#chapter-2">Chapter 2: Agile Methodologies and Their Application</a></li>
                        <li><a href="#chapter-3">Chapter 3: Collaboration with the Development Team</a></li>
                        <li><a href="#chapter-4">Chapter 4: Agile Test Planning</a></li>
                        <li><a href="#chapter-5">Chapter 5: Test Automation</a></li>
                        <li><a href="#chapter-6">Chapter 6: Continuous Testing and Continuous Integration</a></li>
                        <li><a href="#chapter-7">Chapter 7: Exploratory and Adaptive Testing</a></li>
                        <li><a href="#chapter-8">Chapter 8: Rapid Feedback and Retrospectives</a></li>
                        <li><a href="#chapter-9">Chapter 9: Agility in Defect Management</a></li>
                        <li><a href="#chapter-10">Chapter 10: Adaptability and Flexibility</a></li>
                        {/* ... Add more chapters as needed */}
                    </ul>
                </div>
                {/* Table of Contents end */}


                <h2>Let the fun begin :</h2>

                <h3 id='chapter-1'>Chapter 1: Understanding Agile Principles</h3>
                <p>
                    In this chapter, we will explore the fundamentals of agile principles and understand their impact on the testing process.
                </p>

                <h4>Section 1.1: Introduction to Agile Manifesto Values and Principles</h4>
                <p>
                    The fundamental values of the Agile Manifesto are:
                    <br />
                    1. <strong>Individuals and interactions over processes and tools</strong>: Emphasizing communication and collaboration within the team.
                    <br />
                    2. <strong>Working software over comprehensive documentation</strong>: Prioritizing the creation of functional software over focusing solely on documentation.
                    <br />
                    3. <strong>Customer collaboration over contract negotiation</strong>: Working closely with customers to address their changing needs.
                    <br />
                    4. <strong>Responding to change over following a plan</strong>: Being flexible and responsive to changes rather than strictly adhering to an initial plan.
                    <br /><br />
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Imagine you are working on a mobile application development project. Instead of following a rigid plan, you might be open to adjusting features based on user feedback, continuously improving the user experience.
                </p>

                <h4>Section 1.2: Flexibility, Collaboration, and Continuous Feedback</h4>
                <p>
                    - Flexibility is essential in the agile approach, allowing adaptation to changing project needs and swift response to challenges.
                    <br />
                    - Constant collaboration among team members (developers, testers, product owners, etc.) promotes smooth communication and informed decision-making.
                    <br />
                    - Continuous feedback from stakeholders and users helps in continuous product improvement.
                    <br /><br />
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Consider you are working on a project management software. Through flexibility, you could add new features to better address user needs. Regular collaboration with developers would help resolve issues quickly identified during testing, and user feedback would aid in prioritizing future improvements. By understanding these values and practicing flexibility, collaboration, and continuous feedback, you are on the path to becoming a proficient agile tester. The next chapter will delve into different agile methodologies and their application in the testing process.
                </p>

                <hr />


                ____________________


                <h3 id='chapter-2'>Chapter 2: Agile Methodologies and Their Application</h3>
                <p>
                    In this chapter, we will explore different agile methodologies and understand how they apply to the testing process.
                </p>

                <h4>Section 2.1: Discovering Agile Methodologies</h4>
                <ul>
                    <li><strong>Scrum</strong>: An iterative method based on development cycles called "sprints." Key roles include Scrum Master, Product Owner, and the development team.</li>
                    <li><strong>Kanban</strong>: A visual approach to managing tasks and workflow. Tasks are organized on a Kanban board in different columns.</li>
                    <li><strong>Extreme Programming (XP)</strong>: Focuses on technical excellence, development practices like automated testing and pair programming.</li>
                </ul>

                <h4>Section 2.2: Applying Agile Methodologies to Testing</h4>
                <ul>
                    <li><strong>Scrum and Testing</strong>: Testing is integrated into each sprint. Testers work closely with developers to ensure product quality.</li>
                    <li><strong>Kanban and Testing</strong>: Testing tasks are visually managed on the Kanban board. Testers can adjust priorities based on current needs.</li>
                    <li><strong>XP and Testing</strong>: Automated testing is central in XP. Testers and developers collaborate to write tests from the outset.</li>
                </ul>

                <p>
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Suppose you are part of a Scrum team. At the start of each sprint, you participate in sprint planning to identify testing tasks. You collaborate closely with developers to understand features for testing and establish acceptance criteria. During the sprint, you conduct both manual and automated tests, providing continuous feedback to the development team.
                </p>

                <p>
                    In an Agile Scrum context, an Agile QA takes on various essential tasks, such as defining acceptance criteria with the PO, creating test scenarios, conducting functional and non-functional tests, detecting and reporting bugs, and collaborating with the development team to enhance overall product quality. For instance, suggesting API performance improvements or UX enhancements. The Agile QA also plays a role in test automation and continuous integration, contributing to ensuring high-quality product delivery throughout the iterative development process.
                </p>

                <p>
                    By understanding how different agile methodologies integrate into the testing process, you can choose the method that best suits your team or the mix of methods that are most effective for contributing to product quality.
                </p>

                ________________

                <h3 id='chapter-3'>Chapter 3: Collaboration with the Development Team</h3>
                <p>
                    In this chapter, we will explore the importance of continuous collaboration with developers and how to work together to ensure product quality.
                </p>

                <h4>Section 3.1: Importance of Communication and Collaboration</h4>
                <ul>
                    <li>Transparent, constructive, solution-oriented communication among team members (testers, developers, product owners, etc.) is crucial for sharing key product and testing information.</li>
                    <li>Collaboration enhances mutual understanding of requirements, reduces errors, aligns efforts, and increases quality.</li>
                </ul>

                <h4>Section 3.2: Working in Synergy with Developers</h4>
                <ul>
                    <li>Collaborate from the early stages of development to understand features and test scenarios, ideally how the code works to effectively test it.</li>
                    <li>Quickly identify issues by sharing testing information and jointly resolving defects.</li>
                </ul>

                <h4>Section 3.3: Participation in Problem-Solving Sessions</h4>
                <ul>
                    <li>Join brainstorming and problem-solving sessions with developers to discuss defects and solutions.</li>
                    <li>Actively contribute to discussions, offering ideas and suggestions based on your testing expertise.</li>
                </ul>

                <p>
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Imagine you are working on an e-commerce application development project. You collaborate with developers to understand how the online payment feature should be implemented. You propose test scenarios that include various payment methods and conduct tests in parallel with development. When issues arise, you participate in problem-solving sessions to identify causes and develop solutions together. By learning to collaborate closely with the development team, you maximize testing process efficiency and contribute to creating a high-quality product.
                </p>
                ____________________

                <h3 id='chapter-4'>Chapter 4: Agile Test Planning</h3>
                <p>
                    In this chapter, we will explore how to plan tests in an agile manner using user stories and acceptance criteria.
                </p>

                <h4>Section 4.1: Decomposing Requirements into User Stories</h4>
                <ul>
                    <li>User stories are concise, user-centric descriptions of software features.</li>
                    <li>Learn how to break down requirements into smaller, manageable user stories for effective test planning and execution.</li>
                    <li>Never hesitate to seek clarifications; no question is too trivial!</li>
                </ul>

                <h4>Section 4.2: Developing Acceptance Criteria</h4>
                <ul>
                    <li>Acceptance criteria are specific conditions that must be met for a user story to be considered complete.</li>
                    <li>Collaborate with the team, including developers and stakeholders, to develop clear and concrete acceptance criteria.</li>
                </ul>

                <h4>Section 4.3: Using Acceptance Criteria to Guide Testing</h4>
                <ul>
                    <li>Acceptance criteria define expectations for each feature, facilitating the creation of relevant test scenarios.</li>
                    <li>Use these criteria as a basis for developing manual and automated tests, which are vital for successful Agile QA.</li>
                </ul>

                <p>
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Suppose you are working on a user story related to user registration on a website. Acceptance criteria might include items such as "The user must be able to enter their name, email address, and password," and "After registration, a confirmation email should be sent to the user." Based on these criteria, you can create test scenarios to verify each aspect of the registration process, ensuring all conditions are met. For instance, check for minimum and maximum field sizes, excluded characters, password requirements, validation indications on the interface, and more.
                </p>


                ____________________

                <h3 id='chapter-5'>Chapter 5: Test Automation</h3>
                <p>
                    In this chapter, we will explore the importance of test automation and how to use tools to create automated test scripts.
                </p>

                <h4>Section 5.1: Importance of Test Automation</h4>
                <ul>
                    <li>Test automation allows for the rapid and regular execution of repetitive tests, increasing the efficiency of the testing process.</li>
                    <li>Automated tests ensure broader coverage and provide quick feedback on product quality.</li>
                </ul>

                <h4>Section 5.2: Writing Automated Test Scripts</h4>
                <ul>
                    <li>Understand how to write automated test scripts using programming languages such as Java, Python, or JavaScript.</li>
                    <li>Create automated test scenarios to verify software functionality, performance, and security.</li>
                    <li>Follow best practices of automated testing, stay updated through MOOCs, meetups, blogs, or articles.</li>
                </ul>

                <p>
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Suppose you are working on an e-commerce web application. You can use Selenium to automate user interface tests, such as adding products to the cart and the payment process. You will write scripts to simulate real user actions, verifying that each step functions correctly.
                </p>

                <p>
                    This approach increases testing efficiency, reduces repetitive manual efforts, frees up time for exploratory testing, and ensures that software features are regularly evaluated.
                </p>

                ____________________

                <h3 id='chapter-6'>Chapter 6: Continuous Testing and Continuous Integration</h3>
                <p>
                    In this chapter, we will examine how testing fits into the continuous integration process and how to set up a continuous integration pipeline to automate testing.
                </p>

                <h4>Section 6.1: Integrating Testing in Continuous Integration</h4>
                <ul>
                    <li>Continuous integration is a practice of regularly and automatically integrating code changes into a shared codebase.</li>
                    <li>Testing is a key component of continuous integration to ensure that newly integrated code does not break existing features.</li>
                </ul>

                <h4>Section 6.2: Setting Up a Continuous Integration Pipeline</h4>
                <ul>
                    <li>Learn how to set up a continuous integration pipeline using tools like Jenkins or GitLab CI/CD.</li>
                    <li>Automate test execution with every new code integration to obtain rapid feedback on quality.</li>
                </ul>

                <h4>Section 6.3: Analyzing Test Results</h4>
                <ul>
                    <li>Interpret test results to identify potential issues.</li>
                    <li>Use information from the continuous integration pipeline to decide if newly integrated code can be deployed to production.</li>
                </ul>

                <p>
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Let's imagine you are working on a web application development project. Whenever a developer submits a code change, a continuous integration pipeline is triggered. This pipeline automates the execution of unit tests, integration tests, and regression tests to verify that the new changes have not introduced issues. If the tests pass, the code is deemed fit for integration into the project.
                </p>

                <p>
                    By understanding how testing fits into a continuous integration process, you will ensure that code is regularly tested and potential issues are identified early.
                </p>

                ____________________


                <h3 id='chapter-7'>Chapter 7: Exploratory and Adaptive Testing</h3>
                <p>
                    In this chapter, we will explore exploratory testing and how to use it to uncover unforeseen issues in the software.
                </p>

                <h4>Section 7.1: Introduction to Exploratory Testing</h4>
                <ul>
                    <li>Exploratory testing is an approach based on actively exploring the software without following predefined scenarios.</li>
                    <li>Testers use intuition, creativity, and expertise to detect undocumented and unexpected issues.</li>
                    <li>Instead of following predefined test scenarios, testers actively explore the application, trying various interactions to identify potential problems.</li>
                </ul>

                <p>
                    This approach helps discover bugs and vulnerabilities that may not be detected by formal testing methods. Exploratory testing is often flexible and adaptive, providing a holistic perspective on software quality.
                </p>

                <h4>Section 7.2: Conducting Exploratory Testing Sessions</h4>
                <ul>
                    <li>Learn to plan and execute focused exploratory testing sessions for specific areas of the application, e.g., spending 1 hour on the online payment feature.</li>
                    <li>Document your findings, including discovered defects and user interface usability issues (e.g., not responsive on certain screens, encountering an error 500 after a specific action).</li>
                </ul>

                <h4>Section 7.3: Using Results to Improve Testing</h4>
                <ul>
                    <li>Results from exploratory testing can inform the obsolescence of certain automated tests, leading to updates in automated and manual test scenarios.</li>
                    <li>Contribute to enhancing test coverage by applying knowledge gained from exploratory testing.</li>
                </ul>

                <p>
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Let's assume you are working on a task management application. You decide to conduct an exploratory testing session for the task reminder function. While intuitively using the application, you discover an issue when setting a task reminder on a holiday. You document this problem and report it to the team for resolution.
                </p>

                <p>
                    By learning how to conduct exploratory testing, you will be able to uncover issues that predefined tests might not detect. This contributes to improving the overall quality of the product and ensuring a positive user experience.
                </p>
                ____________________


                <h3 id='chapter-8'>Chapter 8: Rapid Feedback and Retrospectives</h3>
                <p>
                    In this chapter, we will discover how continuous feedback and retrospectives help consistently improve the testing process.
                </p>

                <h4>Section 8.1: The Importance of Feedback in the Testing Process</h4>
                <ul>
                    <li>Feedback is essential for identifying issues, gaps, and improvement opportunities.</li>
                    <li>Continuous feedback ensures necessary adjustments are made promptly.</li>
                </ul>

                <h4>Section 8.2: Participation in Team Retrospectives</h4>
                <ul>
                    <li>Retrospectives are periodic meetings where the team reviews the process and identifies ways to enhance it.</li>
                    <li>Actively contribute to retrospectives by sharing your experiences and suggesting improvement ideas.</li>
                </ul>

                <h4>Section 8.3: Analyzing Successes and Failures for Continuous Improvements</h4>
                <ul>
                    <li>Analyze test successes and failures to understand what worked and what can be enhanced.</li>
                    <li>Utilize this analysis to implement corrective and preventive actions.</li>
                </ul>

                <p>
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Let's assume your team recently completed a development sprint and encountered challenges in automating certain tests.
                    During the retrospective, you share these challenges with the team. Together, you discuss ways to improve the test automation process and overcome these obstacles in the future.
                    By learning to leverage continuous feedback and actively participating in retrospectives, you contribute to creating an environment of continuous improvement where each iteration of the testing and development process is more effective than the previous one. You work as a team, avoiding individual silos and advancing collaboratively.
                </p>
                ____________________

                <h3 id='chapter-9'>Chapter 9: Agility in Defect Management</h3>
                <p>
                    In this chapter, we will explore how to effectively manage defects identified during testing in an agile environment.
                </p>

                <h4>Section 9.1: Using a Bug Tracking System</h4>
                <ul>
                    <li>Learn how to use a bug tracking tool such as Jira, Bugzilla, or Trello to document and track defects.</li>
                    <li>Defects identified during testing are logged in the tracking system for later resolution (Bug Backlog).</li>
                </ul>

                <h4>Section 9.2: Prioritizing Defects Based on Impact</h4>
                <ul>
                    <li>Evaluate the impact of defects on the product, user experience, and business to determine their priority.</li>
                    <li>Severe defects or those affecting user experience or having a significant financial impact are often addressed with higher priority.</li>
                    <li>For each bug, ask yourself, "What is the impact of this bug on users, the product, and the company?" to define its criticality.</li>
                    <li>Criticality is defined in collaboration with stakeholders (Product Owner, Developers, QA).</li>
                </ul>

                <h4>Section 9.3: Tracking Defect Lifecycle</h4>
                <ul>
                    <li>Track each defect from its creation to complete resolution.</li>
                    <li>Ensure defects are assigned to the appropriate team for swift resolution.</li>
                </ul>

                <p>
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Let's assume you've identified a major defect in a mobile application causing frequent crashes.
                    You log it in the bug tracking system, assign a high priority, and provide details about the replication steps.
                    The development team takes ownership of the defect, fixes it, and keeps you informed of progress. Once the issue is resolved, you verify the fix and close the defect in the tracking system.
                    Tests to cover the regression of this critical defect have been added.
                    By understanding how to manage defects in an agile manner, you contribute to maintaining a smooth development cycle and ensure identified issues are effectively addressed.
                </p>
                ____________________

                <h3 id='chapter-10'>Chapter 10: Adaptability and Flexibility</h3>
                <p>
                    In this chapter, we will explore the importance of adapting to changes and remaining flexible in a constantly evolving agile testing environment.
                </p>

                <h4>Section 10.1: Understanding the Importance of Adaptability</h4>
                <ul>
                    <li>In an agile environment, needs and priorities can change rapidly. Adaptability is crucial to keep up with these changes.</li>
                    <li>Be prepared to adjust your testing strategies and plans based on new requirements.</li>
                </ul>

                <h4>Section 10.2: Reorganizing Testing Based on Changing Needs</h4>
                <ul>
                    <li>If priorities shift, be ready to reorganize testing accordingly.</li>
                    <li>Use collaboration with the team (Feature Team and/or Chapter) to decide which tests should be prioritized.</li>
                </ul>

                <h4>Section 10.3: Responding to Client Requirement Adjustments</h4>
                <ul>
                    <li>Client needs may evolve as the product develops. Adapt your tests to accommodate these changes.</li>
                    <li>Work closely with the client to understand their new requirements.</li>
                </ul>

                <p>
                    <h4><strong>Concrete Example</strong>:</h4>
                    <br /> Let's assume you're working on an inventory management application development project.
                    Midway through development, the client expresses a need to add a new feature for tracking modification history.
                    You rearrange your tests to prioritize verifying this new feature and collaborate with developers to integrate the necessary changes.
                    By understanding the importance of adaptability and flexibility, you'll be better equipped to address challenges that arise when requirements change.
                    These skills are crucial for success in a constantly evolving agile environment.
                </p>

                ____________________

                <div className='happyRobot'>
                    <h4>Congratulations, you have now explored all ten chapters of this course on agile testing!</h4>

                    <div className='happyRobot2'>  <img className='happyRobot001 vibrate-1' src='../../happy_robot.png' alt='congrat_robot' />
                        <img className='iconKawaii puff-out-center' src='../../bubble001.png' alt='congrat_robot' />
                        <img className='iconKawaii  puff-out-bl' src='../../star001.png' alt='congrat_robot' />

                        <img className='iconKawaii puff-out-center2' src='../../heart001.png' alt='congrat_robot' />
                        <img className='iconKawaii puff-out-center3' src='../../bubble001.png' alt='congrat_robot' />
                        <img className='iconKawaii puff-out-center2' src='../../heart001.png' alt='congrat_robot' />
                        <img className='iconKawaii  puff-out-br' src='../../star001.png' alt='congrat_robot' />
                        <img className='iconKawaii puff-out-center2' src='../../bubble001.png' alt='congrat_robot' />
                        <img className='happyRobot001 vibrate-1' src='../../happy_robotReverse.png' alt='congrat_robot' /></div>
                </div>

            </div >
        </div>
    );
};

export default AgileTest;
