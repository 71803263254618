import React, { useState } from 'react';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BiSolidLeftArrow } from 'react-icons/bi';
import { MdSchool } from 'react-icons/md';
import { FiRewind } from 'react-icons/fi';

const JsonFormatterPage = () => {
  const [inputValue, setInputValue] = useState('');
  const [formattedJson, setFormattedJson] = useState('');
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const isInputEmpty = inputValue.trim() === '';

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFormatJson = async () => {
    try {
      setLoading(true); // Show the spinner
      const response = await axios.post('/api/format-json', { data: inputValue });
      setFormattedJson(response.data.response);
      setCopied(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Hide the spinner
    }
  };

  const handleCopyToClipboard = () => {
    setCopied(true);
  };



  const [isBubbleVisible, setIsBubbleVisible] = useState(false);
  const [bubbleTextIndex, setBubbleTextIndex] = useState(0);
  const bubbleTexts = [
    "Hello there!",
    "I'm AI-Can-learn. I will teach you how to use this cool tool !",
    "But I'm learning for the moment... come back later !"
  ];

  const handleBubbleClick = () => {
    const educate = document.getElementById('educate');
    const clickMe = document.getElementById('clickMe');
    const arrow = document.getElementById('arrow');

    educate.classList.add('hide');
    clickMe.classList.add('hide');
    arrow.classList.add('show');
    setIsBubbleVisible(!isBubbleVisible);

  };

  const handleArrowClick = () => {
    setBubbleTextIndex((bubbleTextIndex + 1) % bubbleTexts.length);
  };




  return (
    <div>

      {/* Add the bubble */}
      <div className='terminator'>
        <div className="bubble-iconReverse3 bounce-in-right" onClick={handleBubbleClick}>
          <div id='clickMe' className='bubbleIconTxtReverse3 bounce-in-right'>Click me !</div>
          <MdSchool id='educate' className='bubbleIconReverse3 bounce-in-right' />
          <BiSolidLeftArrow id='arrow' className='bubbleIconReverse3 bounce-in-right' />
        </div>


        {isBubbleVisible && (
          <div className="bubble-container">
            <div className="bubbleReverse">
              <p className="dialogue-text">{bubbleTexts[bubbleTextIndex]}</p>
              <div className="arrow-btnReverse" onClick={handleArrowClick}>
                <FiRewind />
              </div>
            </div>
          </div>
        )}
        <div class="moving-object-info001Reverse bounce-in-right">
          <img
            src="../../wall_ai002reverse.png"
            alt="Wall-AI Robot"
          /></div>
        {/* End the bubble */}
      </div>
      <div className='form004JSON'>
        <h1>JSON CreAI_tor</h1>
        <label>
          Enter JSON fields you need:
          <br />
          <br />
          <textarea
            type="text"
            className='endpointInput'
            placeholder='Exemple: Name, Id, Location that contain City and Address , Currency'
            rows="4"
            cols="42"
            required
            value={inputValue}
            onChange={handleInputChange}
          />
        </label>
        <br />
        <button className='submitBtnSub' onClick={handleFormatJson} disabled={loading || isInputEmpty}>
          {loading ? 'Creating...' : 'Create JSON'}
        </button>
        <br />
        {formattedJson && (
          <>
            <h2>Created JSON:</h2>
            <pre>{formattedJson}</pre>
            <br />
            <CopyToClipboard className='submitBtnSub' text={formattedJson} onCopy={handleCopyToClipboard}>
              <button>{copied ? 'Copied!' : 'Copy to Clipboard'}</button>
            </CopyToClipboard>
          </>
        )}
      </div>

    </div>
  );
};

export default JsonFormatterPage;
