import Axios from 'axios';
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_STRIPE_REGISTER_REQUEST,
  USER_STRIPE_REGISTER_REQUEST_SUCCESS,
  USER_STRIPE_REGISTER_REQUEST_FAIL,
} from '../constants/userConstants';
import axios from 'axios';
import { toast } from 'react-toastify';


export const register = (email, password) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { email, password } });
  try {
    //console.log('TATATATATTA' + data);
    const { data } = await axios.post('/api/register', {
      email,
      password,
    });
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};



export const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await Axios.post('/api/users/signin', { email, password });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem('userInfo', JSON.stringify(data));
    if (data.stripe_account_id !== undefined) {
      localStorage.setItem('stripeAccountId', data.stripe_account_id);
    }

  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const signout = () => (dispatch) => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('cartItems');
  localStorage.removeItem('shippingAddress');
  localStorage.clear();
  dispatch({ type: USER_SIGNOUT });
  document.location.href = '/login';
};

export const detailsUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.get(`/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${userInfo?.token}` },
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    localStorage.setItem('isStripeAccount', JSON.stringify(data.isStripeAccount));
    if (data.stripe_account_id !== undefined) {
      localStorage.setItem('stripeAccountId', data.stripe_account_id);
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
    if (message === 'Invalid Token') {
      localStorage.removeItem('userInfo');
      document.location.href = '/signin';

    }
  }
};


export const updateUserProfile = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(`/api/users/profile`, user, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    localStorage.setItem('userInfo', JSON.stringify(data));
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
    if (message === 'Invalid Token') {
      localStorage.removeItem('userInfo');
      document.location.href = '/signin';

    }
  }
};


export const updateUser = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(`/api/users/${user._id}`, user, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_FAIL, payload: message });
    if (message === 'Invalid Token') {
      localStorage.removeItem('userInfo');
      document.location.href = '/signin';

    }
  }
};


export const listUsers = () => async (dispatch, getState) => {
  dispatch({ type: USER_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get('/api/users', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });
    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_LIST_FAIL, payload: message });
    if (message === 'Invalid Token') {
      localStorage.removeItem('userInfo');
      document.location.href = '/signin';

    }
  }
};


export const deleteUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.delete(`/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DELETE_FAIL, payload: message });
    if (message === 'Invalid Token') {
      localStorage.removeItem('userInfo');
      document.location.href = '/signin';

    }
  }
};



export const stripeLink = (userId) => async (dispatch) => {
  dispatch({ type: USER_STRIPE_REGISTER_REQUEST, payload: userId });
  try {
    const { data } = axios.post('/api/stripe/stripeLinkCall', { userId })
      .then((res) => {
        toast("Stripe onboarding ON WAY");
        //console.log("Response >>>>", res);
        window.location.href = res.data;
      })
      .catch((err) => {
        //console.log("Response ERROR >>>>", err.response.status, err.message);
        toast("Stripe onboarding failed, Please try again", err);
        // setLoadingUpload(false);
      })

    dispatch({ type: USER_STRIPE_REGISTER_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_STRIPE_REGISTER_REQUEST_FAIL, payload: message });
  }
};





export const stripeLinkCallback = (userId) => async (dispatch) => {
  dispatch({ type: USER_STRIPE_REGISTER_REQUEST, payload: userId });
  try {
    const { data } = axios.post('/api/stripe/get-account-status', { userId })
      .then((res) => {
        toast("Stripe onboarding ON WAY");
        //console.log("Response >>>>", res);
        window.location.href = res.data;
      })
      .catch((err) => {
        //console.log("Response ERROR >>>>", err.response.status, err.message);
        toast("Stripe onboarding failed, Please try again");
        // setLoadingUpload(false);
      })

    dispatch({ type: USER_STRIPE_REGISTER_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_STRIPE_REGISTER_REQUEST_FAIL, payload: message });
  }
};




export const forgetPassword = (email) => async (dispatch) => {
  dispatch({ type: USER_RESET_PASSWORD_REQUEST, payload: email });
  try {
    const { data } = await Axios.post('/api/users/forgetPassword', email);
    dispatch({ type: USER_RESET_PASSWORD_SUCCESS, payload: data });
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};