import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../components/sideBar.css';
import { FaSignInAlt, FaCode, FaFileCode, FaCheckSquare, FaTasks, FaHome, FaAngleRight, } from 'react-icons/fa';
import { RiAdminLine, RiContactsLine } from 'react-icons/ri';
import { BiLogOutCircle } from 'react-icons/bi';
import { MdOutlineContactMail } from 'react-icons/md'
import { VscLaw } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signout } from '../actions/userActions';




export default props => {
    const dispatch = useDispatch();
    const signoutHandler = () => {
        dispatch(signout());
    };

    const userInfo = localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo'))
        : null;

    // Vérification si l'utilisateur est connecté
    const isLoggedIn = userInfo && userInfo.token;

    return (
        <Menu>
            <div className='happyRobot2'>
                <img className='iconKawaii  ' src='../../star001.png' alt='congrat_star' />
                <img className='iconKawaii  ' src='../../star001.png' alt='congrat_star' />
                <img className='iconKawaii  ' src='../../star001.png' alt='congrat_star' />
                <img className='iconKawaii  ' src='../../star001.png' alt='congrat_star' />
                <img className='iconKawaii  ' src='../../star001.png' alt='congrat_star' />
            </div>
            <a className="menu-item" href="/home">
                <FaHome className="icon" />
                Home
            </a>
            {!isLoggedIn && (<>

                <a className="menu-item" href="/registerForm">
                    <RiContactsLine className="icon" />
                    Register
                </a>
                <br />
                <a className="menu-item" href="/login">
                    <FaSignInAlt className="icon" />
                    Login
                </a>
                <br />
                <a className="menu-item" href="/legals">
                    <VscLaw className="icon" />Legal mentions
                </a>
                <a className="menu-item" href="/contactUs">
                    <MdOutlineContactMail className="icon" />Contact Us
                </a>

            </>)}
            {isLoggedIn && (<><a className="menu-item" href="/profile">
                <FaSignInAlt className="icon" />
                Profile
            </a>
                <br />
                <a className="menu-item" href="/subscriptionsScreen">
                    <FaCode className="icon" />
                    Subscriptions
                </a>
                <br />
                <a className="menu-item" href="/api">
                    <FaCode className="icon" />
                    API Tests
                </a>
                <br />
                <a className="menu-item" href="/e2e">
                    <FaFileCode className="icon" />
                    E2E Tests
                </a>
                <br />
                <a className="menu-item" href="/unit">
                    <FaCheckSquare className="icon" />
                    Unit Tests
                </a>
                <br />
                <a className="menu-item" href="/testCases">
                    <FaTasks className="icon" />
                    Test Cases
                </a>
                <br />
                <a className="menu-item" href="/legals">
                    <VscLaw className="icon" />Legal mentions
                </a>
                <a className="menu-item" href="/contactUs">
                    <MdOutlineContactMail className="icon" />Contact Us
                </a>
            </>)}
            <br />
            {isLoggedIn && (<>
                <Link className="logoutLink" to="#signout" onClick={signoutHandler}>
                    < BiLogOutCircle className="icon" /> Logout
                </Link></>)}
            {userInfo && userInfo.isAdmin && (
                <><br />
                    <br />
                    <a className="menu-item" href="/dashboardPage">
                        <RiAdminLine className="icon" />Dashboard
                    </a>
                </>
            )}
            <div className='happyRobot2'>
                <img className='iconKawaii  ' src='../../star001.png' alt='congrat_star' />
                <img className='iconKawaii  ' src='../../star001.png' alt='congrat_star' />
                <img className='iconKawaii  ' src='../../star001.png' alt='congrat_star' />
                <img className='iconKawaii  ' src='../../star001.png' alt='congrat_star' />
                <img className='iconKawaii  ' src='../../star001.png' alt='congrat_star' />
            </div>
        </Menu>
    );
};