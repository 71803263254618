import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { BsArrowUpCircle } from 'react-icons/bs';


const E2EPage = () => {
    const [formData, setFormData] = useState({
        designPattern: '',
        programmingLanguage: '',
        testingTool: '',
        frameworkGherkin: 'no',
        testParams: '',
        testCase: '',
        language: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState('');
    const [language, setLanguage] = useState('english');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'language') {
            setLanguage(value); // Mettez à jour l'état de la langue ici
            setFormData(prevData => ({
                ...prevData,
                language: value // Mettez à jour formData avec la nouvelle valeur de la langue
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const [showBackToTop, setShowBackToTop] = useState(false);

    // Function to handle scroll event and toggle the visibility of the Back to Top button
    const handleScroll = () => {
        const responseContainer = document.getElementById('responseContainer');
        if (responseContainer) {
            setShowBackToTop(window.scrollY > 0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const spinner = document.getElementById('spinner');
        spinner.classList.add('show');

        const button = document.getElementById('callAPIButton');
        button.classList.add('hide');
        const copyButton = document.getElementById('copyButton');
        const btnBackToTop = document.getElementById('btnBackToTop');


        try {
            const response = await axios.post('/api/generate-e2e-test-cases', formData);
            setResponse(response.data.response);
        } catch (error) {
            toast.error('Erreur lors de l\'appel à l\'API:', error);
        } finally {
            spinner.classList.remove('show');
            copyButton.classList.add('show');
            button.classList.remove('hide');
            btnBackToTop.classList.add('show');
            copyButton.scrollIntoView({ behavior: 'smooth' });
            setIsLoading(false);
        };
    };

    const copyResponseToClipboard = () => {
        const responseContainer = document.getElementById('responseContainer');
        const modifiedContent = responseContainer.innerText;

        navigator.clipboard.writeText(modifiedContent)
            .then(() => {
                toast.success('Response copied to clipboard :)');
            })
            .catch(error => {
                toast.error('Error copying to clipboard :', error);
            });
    };


    return (
        <div>
            <ToastContainer position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <form className='form002 slide-in-bottom' onSubmit={handleSubmit}>
                <div className='formUx'>
                    <label>
                        Design Pattern :
                        <select name="designPattern" value={formData.designPattern} onChange={handleChange} required>
                            <option value="">Select a design pattern</option>
                            <option value="Page Object Model">Page Object Model</option>
                            <option value="BDD">BDD</option>
                            <option value="Fluent Interface Pattern">Fluent Interface Pattern</option>
                            <option value="Data-Driven Testing">Data-Driven Testing</option>
                            <option value="Factory Pattern">Factory Pattern</option>
                        </select>
                    </label>
                    <br />
                    <label>
                        Programming Language :
                        <select name="programmingLanguage" value={formData.programmingLanguage} onChange={handleChange} required>
                            <option value="">Select a programming language</option>
                            <option value="Javascript">Javascript</option>
                            <option value="Java">Java</option>
                            <option value="Php">Php</option>
                        </select>
                    </label>
                    <br />
                    <label>
                        Testing Tool :
                        <select name="testingTool" value={formData.testingTool} onChange={handleChange} required>
                            <option value="">Select a testing tool</option>
                            <option value="Selenium">Selenium</option>
                            <option value="Cypress">Cypress</option>
                        </select>
                    </label>
                    <br />
                    <label>
                         Gherkin framework :
                        <select name="frameworkGherkin" value={formData.frameworkGherkin} onChange={handleChange} required>
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no" default>No</option>
                        </select>
                    </label>

                    <br />
                    <label className='labelColor'>
                        Response expected to be in:</label>
                    <select id="langDropdown" name="language" value={language} onChange={handleChange}>
                        <option value="français">Français</option>
                        <option value="english">English</option>
                    </select>
                    <br />
                    <label className='labelColor'>
                        Test params / DOM elements to automate your tests :</label>
                    <textarea
                        rows="10" cols="42"
                        type="text"
                        name="testParams"
                        value={formData.testParams}
                        onChange={handleChange}
                        required
                        placeholder='Add here the DOM elements of the page you want to test or any valuable informations to create your test cases.'
                    />
                    <br />
                    <label className='labelColor'>
                        Test Case to automate :</label>
                    <textarea
                        rows="10" cols="42"
                        type="text"
                        name="testCase"
                        value={formData.testCase}
                        onChange={handleChange}
                        required
                        placeholder='Paste your expect test scenarios here.
                        Example: All possible test cases for a login feature'
                    />
                    <br />
                    <button id='callAPIButton' className='callAPIButton' type="submit" disabled={isLoading}>Generate</button>
                </div>
            </form>

            <div id="spinner"></div>
            {isLoading && <div id="spinner"></div>}
            {!isLoading && response && (
                <div>
                    <pre id='responseContainer' contentEditable="true">{response}</pre>
                </div>
            )}
            <button id='copyButton' className="copyButton" onClick={copyResponseToClipboard}>Copy to Clipboard</button>
            <button
                id="btnBackToTop"
                className='btnBackToTop'
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                style={{ display: showBackToTop ? 'block' : 'none' }}
            >
                <BsArrowUpCircle />
            </button>
        </div>
    );
};

export default E2EPage;
