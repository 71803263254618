import React, { useState } from 'react';


function TextComparePage() {
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [result, setResult] = useState('');

  const handleText1Change = (e) => {
    setText1(e.target.value);
  };

  const handleText2Change = (e) => {
    setText2(e.target.value);
  };

  const compareTexts = () => {
    const lines1 = text1.split('\n');
    const lines2 = text2.split('\n');
    let diff = '';

    lines1.forEach((line1, index) => {
      const line2 = lines2[index] || '';
      if (line1 !== line2) {
        diff += `<span style="background-color: yellow">${line1}</span>\n`;
      } else {
        diff += `${line1}\n`;
      }
    });

    setResult(diff);
  };

  return (
    <div className="form003SQL">
      <h1>Text Comparator</h1>
      <div>
        <h2>Text 1</h2>
        <textarea value={text1} onChange={handleText1Change} rows={10} cols={50} />
      </div>
      <div> 
        <h2>Text 2</h2>
        <textarea value={text2} onChange={handleText2Change} rows={10} cols={50} />
      </div>
      <button className='submitLogin' onClick={compareTexts}>Compare</button>
      <div>
        <h2>Result</h2>
        <pre dangerouslySetInnerHTML={{ __html: result }} />
      </div>
    </div>
  );
}

export default TextComparePage;
