import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SQLQueryBuilder() {
  const [tableName, setTableName] = useState('');
  const [columns, setColumns] = useState('');
  const [selectedOperation, setSelectedOperation] = useState('SELECT');
  const [selectedConditionType, setSelectedConditionType] = useState('WHERE');
  const [selectedCondition, setSelectedCondition] = useState('');
  const [sqlQuery, setSqlQuery] = useState('');
  const [values, setValues] = useState('');
  const [joins, setJoins] = useState([{ table: '', condition: '' }]);

  const handleTableNameChange = (e) => {
    setTableName(e.target.value);
  };

  const handleColumnChange = (e) => {
    const column = e.target.value;
    const columnArray = column
      .split(' ')
      .filter((word) => word.trim() !== '')
      .map((word) => word.trim()); // Supprimer les espaces supplémentaires
    setColumns(columnArray.join(', '));
  };

  const clearForm = () => {
    setTableName('');
    setColumns('');
    setSelectedOperation('SELECT');
    setSelectedConditionType('WHERE');
    setSelectedCondition('');
    setSqlQuery('');
    setValues('');
    setJoins([{ table: '', condition: '' }]);
  };

  const handleValuesChange = (e) => {
    setValues(e.target.value);
  };

  const addJoin = () => {
    setJoins([...joins, { table: '', condition: '' }]);
  };

  const removeLastJoin = () => {
    if (joins.length > 1) {
      const newJoins = [...joins];
      newJoins.pop();
      setJoins(newJoins);
    }
  };

  const handleOperationChange = (e) => {
    setSelectedOperation(e.target.value);
  };

  const handleConditionTypeChange = (e) => {
    setSelectedConditionType(e.target.value);
  };

  const handleConditionChange = (e) => {
    setSelectedCondition(e.target.value);
  };

  const handleJoinTableChange = (e, index) => {
    const newJoins = [...joins];
    newJoins[index].table = e.target.value;
    setJoins(newJoins);
  };

  const handleJoinConditionChange = (e, index) => {
    const newJoins = [...joins];
    newJoins[index].condition = e.target.value;
    setJoins(newJoins);
  };

  const buildSqlQuery = () => {
    // Construction de la requête SQL en fonction de l'opération sélectionnée
    let sqlQuery = '';
    try {
      if (selectedOperation === 'SELECT') {
        if (columns === '') {
          sqlQuery = `SELECT * FROM ${tableName}`;
        } else {
          sqlQuery = `SELECT ${columns} FROM ${tableName}`;
        }
      } else if (selectedOperation === 'INSERT') {
        if (columns && values) {
          sqlQuery = `INSERT INTO ${tableName} (${columns}) VALUES (${values})`;
        } else {
          // Gérer le cas où les colonnes ou les valeurs ne sont pas définies
          throw new Error('Les colonnes et les valeurs sont nécessaires pour une requête INSERT.');
        }
      } else if (selectedOperation === 'UPDATE') {
        // Construction de la requête UPDATE (à personnaliser)
        sqlQuery = `UPDATE ${tableName} SET ... ${selectedConditionType} ...`;
      } else if (selectedOperation === 'DELETE') {
        // Construction de la requête DELETE (à personnaliser)
        sqlQuery = `DELETE FROM ${tableName} ${selectedConditionType} ...`;
      }

      // Ajout de la condition si une condition est sélectionnée
      if (selectedCondition) {
        sqlQuery += ` ${selectedConditionType} ${selectedCondition}`;
      }

      // Ajout de la jointure si une table de jointure est sélectionnée
      if (joins.length > 0) {
        sqlQuery += joins.map((join) => ` JOIN ${join.table} ON ${join.condition}`).join('');
      }

      // Définition de la requête SQL finale
      setSqlQuery(sqlQuery);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="form002 sql-query-builder">
      <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <h1>SQL Query Builder</h1>
      <label>
        Opération SQL :
        <select value={selectedOperation} onChange={handleOperationChange}>
          <option value="SELECT">SELECT</option>
          <option value="INSERT">INSERT</option>
          <option value="UPDATE">UPDATE</option>
          <option value="DELETE">DELETE</option>
        </select>
      </label>
      <br />
      {selectedOperation === 'SELECT' && (
        <div>
          <label>
            Colonnes :
            <input type="text" placeholder="Nom de la/les colonne(s)" onChange={handleColumnChange} />
          </label>
        </div>
      )}
      <label>
        Nom de la table :
        <input type="text" value={tableName} onChange={handleTableNameChange} />
      </label>
      {selectedOperation !== 'INSERT' && (
        <div>
          <label>
            Condition :
            <select value={selectedConditionType} onChange={handleConditionTypeChange}>
              <option value="WHERE">WHERE</option>
              <option value="AND">AND</option>
              <option value="OR">OR</option>
              {/* Ajoutez d'autres types de conditions ici */}
            </select>
            <input type="text" placeholder="Condition SQL" onChange={handleConditionChange} />
          </label>
        </div>
      )}
      ------------------------------------
      {selectedOperation === 'INSERT' && (
        <div>
          <label>
            Colonnes :
            <input type="text" placeholder="Nom de la/les colonne(s)" onChange={handleColumnChange} />
          </label>
          <label>
            Values :
            <input type="text" placeholder="Valeurs à insérer" onChange={handleValuesChange} />
          </label>
        </div>
      )}
      {selectedOperation === 'SELECT' && (
        <div>
          {joins.map((join, index) => (
            <div key={index}>
              <label>
                Table de jointure :
                <input
                  type="text"
                  placeholder="Nom de la table de jointure"
                  value={join.table}
                  onChange={(e) => handleJoinTableChange(e, index)}
                />
              </label>
              <label>
                Condition de jointure :
                <input
                  type="text"
                  placeholder="Condition de jointure"
                  value={join.condition}
                  onChange={(e) => handleJoinConditionChange(e, index)}
                />
              </label>
              {index === joins.length - 1 && (
                <button class="btnBase64" onClick={addJoin}>Ajouter une jointure</button>
              )}
              {index === joins.length - 1 && index !== 0 && (
                <button class="btnBase64" onClick={removeLastJoin}>Supprimer la dernière jointure</button>
              )}
              
      ------------------------------------
            </div>
          ))}
        </div>
      )}
      <br />
      <button class="btnBase64" onClick={buildSqlQuery}>Construire la requête SQL</button>
      {sqlQuery && (
        <div className="result">
          <h2>Requête SQL générée :</h2>
          <pre>{sqlQuery}</pre>
          <button class="btnBase64" onClick={clearForm}>Clear</button>
        </div>
      )}
    </div>
  );
}

export default SQLQueryBuilder;
