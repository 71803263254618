import React from 'react';
import { Navigate } from 'react-router-dom';

const PaymentPage = () => {
 
  const handlePayment = () => {
    // Logique de paiement
    // ...

    // Redirection vers la page de rappel des licences actives
    Navigate('/licenses');
  };

  return (
    <div>
      <h2>Payment page</h2>
      <button onClick={handlePayment}>Make payment</button>
    </div>
  );
};

export default PaymentPage;