import React from 'react';

export default function LegalsPage() {
    return (
        <div>
            <h2>Legal Mentions</h2>
            <img
                className="fit-picture"
                src="../../wip-bg.png"
                alt="WIP LOGO"
            />
            <p className='legalsTxt'>Mentions légales

                1. Informations générales

                La présente application web est exploitée par [Nom de l'entreprise / Organisation] (ci-après dénommée "nous", "notre" ou "nos"). Nous sommes une société / organisation enregistrée [Indiquer le numéro d'enregistrement et le pays de l'entreprise/organisation].

                Adresse du siège social :
                [Adresse complète du siège social]

                Adresse e-mail de contact :
                [Adresse e-mail de contact]

                Numéro de téléphone :
                [Numéro de téléphone de contact]

                2. Propriété intellectuelle

                Tous les droits de propriété intellectuelle relatifs à l'application web et à son contenu (y compris, mais sans s'y limiter, les textes, les graphiques, les logos, les images, les vidéos, les sons, les données et le code source) sont la propriété exclusive de [Nom de l'entreprise / Organisation] ou de ses concédants de licence. Toute utilisation non autorisée, reproduction ou distribution de ces éléments est strictement interdite.

                3. Utilisation de l'API OpenAI

                Notre application web utilise l'API OpenAI pour fournir certains services ou fonctionnalités. En utilisant notre application, vous acceptez également les conditions d'utilisation de l'API OpenAI. Veuillez consulter les conditions d'utilisation de l'API OpenAI sur leur site officiel.

                4. Responsabilité

                Nous nous efforçons de maintenir l'exactitude et la pertinence des informations fournies par notre application web, mais nous ne pouvons garantir leur complétude, leur exactitude ou leur actualité. L'utilisation de notre application web se fait à vos propres risques.

                Nous déclinons toute responsabilité en cas de dommages directs, indirects, accidentels, consécutifs, spéciaux ou punitifs résultant de l'utilisation de notre application web ou de l'incapacité d'utiliser celle-ci.

                5. Liens vers des sites tiers

                Notre application web peut contenir des liens vers des sites tiers qui ne sont pas sous notre contrôle. Nous ne sommes pas responsables du contenu de ces sites tiers ni des produits, services ou informations qu'ils proposent. L'accès à ces liens se fait à vos propres risques.

                6. Protection des données personnelles

                Nous attachons une grande importance à la protection de vos données personnelles. Notre politique de confidentialité explique comment nous collectons, utilisons et protégeons vos données personnelles. Veuillez consulter notre politique de confidentialité pour en savoir plus.

                7. Modifications des mentions légales

                Nous nous réservons le droit de modifier les présentes mentions légales à tout moment. Toute modification sera publiée sur cette page et prendra effet immédiatement. Nous vous encourageons à consulter régulièrement cette page pour rester informé des mises à jour.

                Date de la dernière mise à jour : [Date de la dernière mise à jour des mentions légales]

                Pour toute question ou préoccupation concernant les présentes mentions légales, veuillez nous contacter à l'adresse e-mail [Adresse e-mail de contact].

                Merci d'utiliser notre application web !

                L'équipe [Nom de l'entreprise / Organisation] @ZI-C17
            </p>
            <br />
            <p className='legalsTxt'>Legal Notices

                1. General Information

                This web application is operated by [Company/Organization Name] (hereinafter referred to as "we," "our," or "us"). We are a registered company/organization [Provide registration number and country of the company/organization].

                Registered Office Address:
                [Complete registered office address]

                Contact Email Address:
                [Contact email address]

                Phone Number:
                [Contact phone number]

                2. Intellectual Property

                All intellectual property rights related to the web application and its content (including, but not limited to, texts, graphics, logos, images, videos, sounds, data, and source code) are the exclusive property of [Company/Organization Name] or its licensors. Any unauthorized use, reproduction, or distribution of these elements is strictly prohibited.

                3. Use of the OpenAI API

                Our web application uses the OpenAI API to provide certain services or features. By using our application, you also agree to the terms of use of the OpenAI API. Please refer to the OpenAI API terms of use on their official website.

                4. Liability

                We strive to maintain the accuracy and relevance of the information provided by our web application, but we cannot guarantee its completeness, accuracy, or timeliness. The use of our web application is at your own risk.

                We disclaim any liability for direct, indirect, incidental, consequential, special, or punitive damages resulting from the use of our web application or the inability to use it.

                5. Links to Third-Party Sites

                Our web application may contain links to third-party sites that are not under our control. We are not responsible for the content of these third-party sites or the products, services, or information they offer. Access to these links is at your own risk.

                6. Personal Data Protection

                We attach great importance to the protection of your personal data. Our privacy policy explains how we collect, use, and protect your personal data. Please refer to our privacy policy for more information.

                7. Changes to Legal Notices

                We reserve the right to modify these legal notices at any time. Any changes will be posted on this page and will take effect immediately. We encourage you to regularly review this page to stay informed of updates.

                Last Updated: [Date of the last update of the legal notices]

                For any questions or concerns regarding these legal notices, please contact us at the email address [Contact email address].

                Thank you for using our web application!

                The [Company/Organization Name] Team
            </p>
        </div>
    );
}