import React from 'react';
import './homepage.css';
import { BrowserRouter, Link, useNavigate } from 'react-router-dom';


const HomePage = () => {

    const userInfo = localStorage.userInfo;
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/registerForm");
    };
    const handleClickLogin = () => {
        navigate("/login");
    };
    return (

        <div className='homepage'>
            <h1 className='homeHeader'>Welcome to our quality toolbox !</h1>
            <h1 className='betaTxt'>THIS IS AN ALPHA VERSION !</h1>
            <div class="moving-object">
                <img
                    src="../../WALL-AI003Small.png"
                    alt="Robot"
                /></div>
            <p>Unleash the futur of software testing, the AI at your disposal to accelerate value delivery and the overall quality of your business.</p>
            {!userInfo && (<div className='registerLoginBtn'><button className='registerHome' style={{ marginRight: '30px' }} onClick={handleClick}>Register</button>
                <button className='registerHome' onClick={handleClickLogin}>Login</button></div>)}
            <div className='mainHomeContainer'>
                <div className='item001 slide-in-bottom'>

                    <Link className="link-styles" to="/api">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem"
                                src="../../postman.png"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>API TestMaker</h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot005Tiny.png' alt='robot' />
                            Generate API tests and past them directly to Postman, increase your general quality and save time

                        </div>
                    </Link>
                </div>
                <div className='item002 slide-in-bottom'>
                    <Link className="link-styles2" to="/e2e">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem"
                                src="../../e2eLogo.jpg"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>E2E_Magic_AI</h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot004Tiny.png' alt='robot' />
                            Generate Cypress or Selenium tests and past them directly to your project, with Page Object Model or an other design pattern
                        </div>
                    </Link>
                </div>
                <div className='item004 slide-in-bottom'>
                    <Link className="link-styles2" to="/testCases">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem"
                                src="../../userStories.png"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>AI_Story TestMaker</h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot002Tiny.png' alt='robot' />
                            Generate test cases and acceptance criteria from your User Story, increase your general quality and save time

                        </div>
                    </Link>
                </div>
                <div className='item003 slide-in-bottom'>
                    <Link className="link-styles3" to="/unit">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem2"
                                src="../../unitTestsSmall2.png"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>Unit_Ai Testify</h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot002Tiny.png' alt='robot' />
                            Generate Unit tests for your functions, increase your general quality and save time

                        </div>
                    </Link>
                </div>
                <div className='item004 slide-in-bottom'>

                    <Link className="link-styles2" to="/jsonFormat">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem"
                                src="../../json.png"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>Json CreAI_tor</h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot004Tiny.png' alt='robot' />
                            Generate and format JSON file for your tests activities

                        </div>
                    </Link>
                </div>
                <div className='item003 slide-in-bottom'>

                    <Link className="link-styles2" to="/mimifierTool">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem"
                                src="../../js.png"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>JS_Mimifier</h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot002Tiny.png' alt='robot' />
                            Mimify your JS and NodeJs code to get a better performance and increase your users satisfaction.

                        </div>
                    </Link>
                </div>
                <div className='item001 slide-in-bottom'>

                    <Link className="link-styles" to="/sqlPerfector">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem"
                                src="../../sql_nosql1.png"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>Ninj_AI QueryZard</h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot004Tiny.png' alt='robot' />
                            Have a perfect, optimised SQL or NoSQL query has never been so easy. Paste your query and AI will make it even more perfect !
                        </div>
                    </Link>
                </div>
                <div className='item004 slide-in-bottom'>
                    <Link className="link-styles2" to="/courseList">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem"
                                src="../../toolBox-Small.png"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>M_AI Learning </h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot003Tiny.png' alt='robot' />
                            Explore our toolbox and discover the captivating world of learning and continuous improvement to elevate your web applications quality.

                        </div>
                    </Link>
                </div>
                <div className='item003 slide-in-bottom'>
                    <Link className="link-styles3" to="/Base64Tool">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem2"
                                src="../../decode_color.png"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>Base64_It Tool</h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot002Tiny.png' alt='robot' />
                            Code and decode easily your base64 files for your tests, increase your general quality and save time

                        </div>
                    </Link>
                </div>
                <div className='item001 slide-in-bottom'>

                    <Link className="link-styles" to="/SqlGenPage">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem"
                                src="../../sql_icon.png"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>SQL_Req_Builder</h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot005Tiny.png' alt='robot' />
                            Generate SQL request and past them directly to your database, increase your general quality and save time

                        </div>
                    </Link>
                </div>
                { /* <div className='item004 slide-in-bottom'>
                    <Link className="link-styles2" to="/DynamoDb">
                        <div className='itemRow'>
                            <img
                                className="fit-pictureItem"
                                src="../../AWS_Dynamo.jpg"
                                alt="WIP LOGO"
                            />
                            <h2 className='itemHeader'>AI_Story TestMaker</h2>
                        </div>
                        <div className='itemTxt'>
                            <img className='robotIconMain jello-vertical' src='../../robot002Tiny.png' alt='robot' />
                            Generate test cases and acceptance criteria from your User Story, increase your general quality and save time

                        </div>
                    </Link>
    </div>*/}
            </div >
        </div >

    );
};

export default HomePage;
