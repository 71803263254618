import React, { useEffect, useState } from 'react';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';

const PrivateRoute = ({ children }) => {
    const [isValidSubscription, setIsValidSubscription] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const userInfos = JSON.parse(localStorage.getItem('userInfo'));
    //const userId = userInfos._id;
    const [isAuthenticated, setIsAuthenticated] = useState();

    useEffect(() => {
        if (!userInfos) {
            setError('Erreur: userInfos est null');
            setLoading(false);
            setIsAuthenticated(false);
            return;
        }

        const fetchSubscriptionValidity = async () => {

            try {
                const response = await axios.get(`/api/isValidSubscription/${userInfos._id}`, {
                    headers: { Authorization: `Bearer ${userInfos?.token}` },
                });
                //console.log(response.data.isValid);
                const isValid = response.data.isValid;
                setIsValidSubscription(isValid);
                setIsAuthenticated(true);
            } catch (error) {
                console.error('Une erreur est survenue lors de la vérification de l\'abonnement', error);
                setError('Une erreur est survenue lors de la vérification de l\'abonnement');
                setIsAuthenticated(false);
            } finally {
                setLoading(false);
            }

        };
        if (userInfos) {
            fetchSubscriptionValidity();
        }
    }, [userInfos]);

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/subscriptionsScreen');
    };


    const handleClickRegister = () => {
        navigate('/registerForm');
    };


    if (loading) {
        return <div>Chargement...</div>;
    }

    if (error) {
        if (!userInfos) {
            return <div className='privateRouteItem'><h1>You do not have access to this page, you must create an account </h1><button className='privateRouteBtn' onClick={handleClickRegister}>Create an account</button></div>
        } else if (!isValidSubscription) {
            return (
                <div className='privateRouteItem'>
                    <h1>You do not have access to this page, your license has probably expired</h1>
                    <button className='privateRouteBtn' onClick={handleClick}>Subscribe</button>
                </div>
            );
        }
    }

    return isValidSubscription ? children : <Navigate to="/home" />;
};

export default PrivateRoute;