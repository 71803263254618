import React, { useState } from 'react';
import { DOMParser, XMLSerializer } from 'xmldom';

const XmlValidator = () => {
  const [xmlInput, setXmlInput] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [validationError, setValidationError] = useState(null);
  const [parsedXml, setParsedXml] = useState(null);

  const handleXmlChange = (e) => {
    setXmlInput(e.target.value);
  };

  const validateXml = () => {
    try {
      const doc = new DOMParser().parseFromString(xmlInput, 'text/xml');
      setParsedXml(doc);
      setIsValid(true);
      setValidationError(null);
    } catch (error) {
      setIsValid(false);
      setValidationError(error.message);
    }
  };

  const correctXml = () => {
    try {
      const doc = new DOMParser().parseFromString(xmlInput, 'text/xml');
      const correctedXml = new XMLSerializer().serializeToString(doc);
      setXmlInput(correctedXml);
      setValidationError(null);
    } catch (error) {
      setValidationError(error.message);
    }
  };

  return (
    <div>
      <h1>XML Validator</h1>
      <textarea
        rows="10"
        cols="50"
        placeholder="Enter XML"
        value={xmlInput}
        onChange={handleXmlChange}
      />
      <button onClick={validateXml}>Validate XML</button>
      <button onClick={correctXml}>Correct XML</button>
      {!isValid && (
        <div>
          <p style={{ color: 'red' }}>Invalid XML:</p>
          <p>{validationError}</p>
        </div>
      )}
      {parsedXml && (
        <div>
          <h2>Parsed XML:</h2>
          <pre>{parsedXml.documentElement.outerHTML}</pre>
        </div>
      )}
    </div>
  );
};

export default XmlValidator;
