import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const DashboardPage = () => {
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [newExpirationDate, setNewExpirationDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const isAdmin = userInfo.isAdmin;

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/api/admin/users', {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`,
                    isAdmin: userInfo?.isAdmin
                }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };


    const handleUserSelect = (userId) => {
        setSelectedUserId(userId);
    };

    const handleExpirationDateChange = (e) => {
        setNewExpirationDate(e.target.value);
    };

    const handleUpdateExpiration = async () => {
        if (!newExpirationDate) return;

        setIsLoading(true);

        try {
            await axios.patch(
                `/api/editSubscription/${selectedUserId}`,
                {
                    newExpirationDate
                },
                {
                    headers: {
                        Authorization: `Bearer ${userInfo?.token}`,
                        isAdmin: userInfo?.isAdmin.toString(),
                    },
                }
            );
            fetchUsers();
            setSelectedUserId('');
            setNewExpirationDate('');
        } catch (error) {
            console.error('Error updating expiration:', error);
        } finally {
            setIsLoading(false);
        }
    };


    const navigate = useNavigate();

    if (!isAdmin) {
        // Redirigez vers "/home" en cas d'accès refusé
        setTimeout(() =>
            navigate("/home"), 1500);
        return <div>Access denied. Only admin users can access this page.</div>;
    }

    return (
        <div>
            <h2>Admin Page</h2>
            <table className='dashboardTab'>
                <thead>
                    <tr>
                        <th>User ID</th>
                        <th>Username</th>
                        <th>License Expiration</th>
                        <th>Subscription ID</th>
                        <th>Edit Expiration Date</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        users.length > 0 &&
                        users.slice().reverse().map((user) => {
                            const renewDate = user.subscription ? user.subscription.renewDate : null;
                            const formattedRenewDate = renewDate ? format(new Date(renewDate), 'dd/MM/yyyy HH:mm') : 'N/A';
                            const isExpired = renewDate ? new Date(renewDate) < new Date() : false;

                            return (
                                <tr key={user.name}>
                                    <td>{user._id}</td>
                                    <td>{user.email}</td>
                                    <td className={isExpired ? 'expired-date' : ''}>{formattedRenewDate}</td>
                                    <td>{user.subscription ? (user.subscription._id ? user.subscription._id : 'N/A') : 'N/A'}</td>
                                    <td>
                                        {user.subscription && <button className='submitProfileUpdate' onClick={() => handleUserSelect(user._id)}>
                                            Edit Expiration
                                        </button>}
                                        {selectedUserId === user._id && (
                                            <div>
                                                <h3>Edit License Expiration</h3>
                                                <input
                                                    type="datetime-local"
                                                    value={newExpirationDate}
                                                    onChange={handleExpirationDateChange}
                                                />
                                                <button className='submitProfileUpdate' onClick={handleUpdateExpiration} disabled={isLoading}>
                                                    Update
                                                </button>
                                            </div>
                                        )}

                                    </td>
                                </tr>
                            );
                        })
                    }



                </tbody>
            </table>
        </div>
    );
};

export default DashboardPage;
