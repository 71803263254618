import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { BiSupport } from 'react-icons/bi';
import { MdOutlineInfo } from 'react-icons/md';

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const maxMessageLength = 512; // Nombre maximal de caractères pour le champ message


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Envoyer les données du formulaire au backend
        await axios.post('/api/send-email', formData)
            .then((res) => {
                toast.success(res.data.message);
                //console.log('E-mail envoyé avec succès !');
                //console.log(res);
                // Réinitialiser le formulaire après l'envoi de l'e-mail
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                });
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                console.error('Erreur lors de l\'envoi de l\'e-mail :', err);
            });
    };

    return (
        <div>
            <ToastContainer position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />

            <p className='contactTxt'>
                <BiSupport /> Have a question, suggestion, or feedback? <MdOutlineInfo />
                <br />
                We'd love to hear from you!
                <br />
                Please fill out the form below, and we'll get back to you as soon as possible.
                <br />
                You can reach us by email at
                <a className=' mailtoTxt' href="mailto:contact@ia-qa.com"> contact@ia-qa.com</a> .
                <br />
                We value your input and are committed to providing excellent customer service. 
            </p>
            <div className='form002contact'>
                <h1>Contact us</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Nom :</label>
                        <input type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            maxlength="256"
                            pattern="^[a-zA-Z0-9\- ]*$" />
                    </div>
                    <div>
                        <label>Email :</label>
                        <input type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            maxlength="256" />
                    </div>
                    <div>
                        <label>Subject :</label>
                        <input type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                            maxlength="256" />
                    </div>
                    <div>
                        <label>Message :</label>
                        <textarea rows="5" cols="42"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            maxLength={maxMessageLength} />
                    </div>
                    <div>Remaining characters: {maxMessageLength - formData.message.length}</div>
                    <button className='submitBtnSub' type="submit">Send Mail</button>
                </form>
            </div >
        </div >
    );
};

export default ContactPage;
