import React from 'react';

class BackButton extends React.Component {
    goBack = () => {
        window.history.back();
    };

    render() {
        return (
            <div className='btnComponent'>
                <button className="buttonReturn" onClick={this.goBack}>
                    <img className='iconKawaii2 jello-horizontal' src='../../kawaii001Tiny.png' alt='congrat_heart' />
                    Back to courses list
                    <img className='iconKawaii2 jello-horizontal' src='../../kawaii001Tiny.png' alt='congrat_heart' />
                </button>
                <br />
            </div>
        );
    }
}

export default BackButton;
