import React, { useState, useEffect } from 'react';

function LicensesPage() {
  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    fetch('/api/licenses')
      .then((response) => response.json())
      .then((data) => setLicenses(data));
  }, []);

  return (
    <ul>
      {licenses.map((license) => (
        <li key={license.id}>{license.type}</li>
      ))}
    </ul>
  );
}


export default LicensesPage;