import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import './signIn.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubscriptionDetails from './SubscriptionDetails';
import { fetchSubscriptionValidity } from '../actions/subscriptionActions';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

const ProfilePage = () => {
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        company: '',
        password: '',
        confirmPassword: ''
    });
    const [loggedIn, setLoggedIn] = useState(false);
    //const [validSubscription, setIsValidSubscription] = useState(false);
    // const [formattedDate, setIsFormattedDate] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const userInfos = JSON.parse(localStorage.getItem('userInfo'));
    const subscription = JSON.parse(localStorage.getItem('subscription'));
    const formattedRenewDate = subscription ? format(new Date(subscription.renewDate), 'dd/MM/yyyy HH:mm') : 'N/A';

    useEffect(() => {
        // Vérifier si les informations d'utilisateur sont présentes dans le localStorage
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
            setUserInfo(JSON.parse(storedUserInfo));
            setLoggedIn(true);
        }
    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserInfo((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfos) {
            dispatch(fetchSubscriptionValidity(userInfos));
        }
    }, [dispatch, userInfos]);






    const handleSubmit = (e) => {
        e.preventDefault();

        // Appeler l'endpoint PUT /profile avec les données du profil modifiées
        axios.put('/api/profile', userInfo, {
            headers: { Authorization: `Bearer ${userInfo?.token}` },
        })
            .then((response) => {
                // Enregistrement des nouvelles informations d'utilisateur dans le localStorage
                localStorage.setItem('userInfo', JSON.stringify(response.data));
                toast.success('Profil mis à jour avec succès !');
                //alert('Profil mis à jour avec succès !');
            })
            .catch((error) => {
                console.error(error);
                toast.error('Une erreur s\'est produite lors de la mise à jour du profil.');
                //alert('Une erreur s\'est produite lors de la mise à jour du profil.');
            });
    };

    const userConnected = localStorage.userInfo;

    if (!userConnected) {
        return <Navigate to="/home" />;
    }

    //TODO gérer la license trial activée après coup 
    return (
        <>
            {userInfo && userInfo.token && (
                <div className='profilePage'>
                    <ToastContainer position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
                    <h2>Hello {userInfo.firstName}</h2>
                    <p className='profileTxt'>(TODO)Active license type: {userInfo.license}<br /> License active until the date of {formattedRenewDate}</p>
                    <form className='formSignIn' onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email">Email :</label>
                            <input
                                required
                                type="email"
                                id="email"
                                name="email"
                                value={userInfo.email}
                                onChange={handleInputChange}
                                maxlength="256"
                            />
                        </div>
                        <div>
                            <label htmlFor="firstName">First Name :</label>
                            <input
                                required
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={userInfo.firstName}
                                onChange={handleInputChange}
                                maxlength="256"
                                pattern="^[a-zA-Z0-9\- ]*$"
                            />
                        </div>
                        <div>
                            <label htmlFor="lastName">Last Name :</label>
                            <input
                                required
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={userInfo.lastName}
                                onChange={handleInputChange}
                                maxlength="256"
                                pattern="^[a-zA-Z0-9\- ]*$"
                            />
                        </div>
                        <div>
                            <label htmlFor="company">Company :</label>
                            <input
                                type="text"
                                id="company"
                                name="company"
                                value={userInfo.company}
                                onChange={handleInputChange}
                                maxlength="256"
                                pattern="^[a-zA-Z0-9\- ]*$"
                            />
                        </div>
                        <div>
                            <label htmlFor="password">Password :</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={userInfo.password}
                                onChange={handleInputChange}
                                maxlength="256"
                                pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$'
                                minlength="8"
                                placeholder='8 char min, Maj, Min, Num'
                            />
                        </div>
                        <div>
                            <label htmlFor="confirmPassword">Confirm password :</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={userInfo.confirmPassword}
                                maxlength="256"
                                onChange={handleInputChange}
                                pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$'
                                minlength="8"
                                placeholder='8 char min, Maj, Min, Num'
                            />
                        </div>
                        <button className='submitProfileUpdate' type="submit">Update profile</button>
                    </form>
                    <SubscriptionDetails />
                </div>)}
        </>
    );
};

export default ProfilePage;