import React, { useState, useEffect } from 'react';
import '../App.css';
import { BsArrowUpCircle } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { BiSolidRightArrow } from 'react-icons/bi';
import { TbPlayerTrackNext } from 'react-icons/tb'
import { MdSchool } from 'react-icons/md';

const UnitTestsPage = () => {
    const [formData, setFormData] = useState({
        functionDocumentation: '',
        unitTestFramework: 'Unit Test Framweork',
        methodCode: '',
        testScenarios: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState('');

    /* window.addEventListener('load', () => {
         const element = document.getElementById('callAPIButton');
         element.scrollIntoView({ behavior: 'smooth' });
 
     });*/

    const copyResponseToClipboard = () => {
        const responseContainer = document.getElementById('responseContainer');

        const textToCopy = responseContainer.innerText;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success('Response copied to clipboard :)');
            })
            .catch(error => {
                console.error('Error copying to clipboard :', error);
            });


    };



    const [isBubbleVisible, setIsBubbleVisible] = useState(false);
    const [bubbleTextIndex, setBubbleTextIndex] = useState(0);
    const bubbleTexts = [
        "Hello there!",
        "I'm AI-ducator. I will teach you how to use this cool tool !",
        "But I'm learning for the moment... come back later !"
    ];

    const handleBubbleClick = () => {
        const educate = document.getElementById('educate');
        const clickMe = document.getElementById('clickMe');
        const arrow = document.getElementById('arrow');

        educate.classList.add('hide');
        clickMe.classList.add('hide');
        arrow.classList.add('show');
        setIsBubbleVisible(!isBubbleVisible);

    };

    const handleArrowClick = () => {
        setBubbleTextIndex((bubbleTextIndex + 1) % bubbleTexts.length);
    };




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const spinner = document.getElementById('spinner');
        spinner.classList.add('show');

        const button = document.getElementById('callAPIButton');
        button.classList.add('hide');
        const copyButton = document.getElementById('copyButton');
        const btnBackToTop = document.getElementById('btnBackToTop');


        const dataToSend = {
            Functions_description: formData.functionDocumentation,
            Unit_Test_framework: formData.unitTestFramework,
            Code_of_the_function: formData.methodCode,
            tests_case_minimum_expected: formData.testScenarios
        };

        try {
            const response = await axios.post('/api/generate-response-unit', dataToSend); // Send the form data to the backend endpoint
            const generatedResponse = response.data.response;
            setResponse(generatedResponse);
        } catch (error) {
            console.error('Error calling backend API:', error);
        } finally {
            spinner.classList.remove('show');
            copyButton.classList.add('show');
            button.classList.remove('hide');
            btnBackToTop.classList.add('show');
            copyButton.scrollIntoView({ behavior: 'smooth' });
            setIsLoading(false);
        };
    };

    const [showBackToTop, setShowBackToTop] = useState(false);

    // Function to handle scroll event and toggle the visibility of the Back to Top button
    const handleScroll = () => {
        const responseContainer = document.getElementById('responseContainer');
        if (responseContainer) {
            setShowBackToTop(window.scrollY > 0);
        }
    };

    // Add scroll event listener when the component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <ToastContainer position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />

            {/* Add the bubble */}
            <div className='terminator'>
                <div className="bubble-icon bounce-in-left" onClick={handleBubbleClick}>
                    <div id='clickMe' className='bubbleIconTxt bounce-in-left'>Click me !</div>
                    <MdSchool id='educate' className='bubbleIcon bounce-in-left' />
                    <BiSolidRightArrow id='arrow' className='bubbleIcon bounce-in-left' />
                </div>


                {isBubbleVisible && (
                    <div className="bubble-container">
                        <div className="bubble">
                            <p className="dialogue-text">{bubbleTexts[bubbleTextIndex]}</p>
                            <div className="arrow-btn" onClick={handleArrowClick}>
                                <TbPlayerTrackNext />
                            </div>
                        </div>
                    </div>
                )}
                <div class="moving-object-info001 bounce-in-left">
                    <img
                        src="../../WALL_AI_001.png"
                        alt="Wall-AI Robot"
                    /></div>
            </div>
            {/* End the bubble */}

            <form className='form001 slide-in-bottom' onSubmit={handleSubmit}>
                <label className='labelColor'>
                    Purpose of the function / method:
                </label>
                <textarea
                    rows="4" cols="42"
                    type="text"
                    name="functionDocumentation"
                    value={formData.functionDocumentation}
                    onChange={handleChange}
                    required
                />

                <br />
                <label className='labelColor'>
                    Test framework:
                </label>
                <select
                    name="unitTestFramework"
                    value={formData.unitTestFramework}
                    onChange={handleChange}
                    required
                    defaultValue="testframework">
                    <option value="testframework" disabled >Test framework</option>
                    <option value="junit">J Unit</option>
                    <option value="mocha">Mocha</option>
                    <option value="jest">JEST</option>
                    <option value="nunit">N Unit</option>
                    <option value="phpunit">PHP Unit</option>
                </select>

                <br />
                <label className='labelColor'>
                    Code of your function to test :</label>
                <textarea
                    rows="4" cols="42"
                    type="text"
                    name="methodCode"
                    value={formData.methodCode}
                    onChange={handleChange}
                    required
                />

                <br />
                <label className='labelColor'>
                    Expected tests cases:</label>
                <textarea
                    rows="5" cols="42"
                    type="text"
                    name="testScenarios"
                    value={formData.testScenarios}
                    onChange={handleChange}
                />

                <br />
                <button type="submit" id='callAPIButton' className='callAPIButton' disabled={isLoading}>
                    {isLoading ? 'Generating...' : 'Generate'}
                </button>
            </form>
            <br />
            <div id="spinner"></div>
            {isLoading && <div id="spinner"></div>}
            {!isLoading && response && (
                <pre id="responseContainer">{response}
                </pre>
            )}
            <h3 className='importResponseTxt'> Copy the code and paste it into your application, do not forget to replace the variables if the generated ones are examples.
            </h3>
            <div>
                <button id="copyButton" className="copyButton" onClick={copyResponseToClipboard}>Copy to clipboard</button>
                <button
                    id="btnBackToTop"
                    className='btnBackToTop'
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    style={{ display: showBackToTop ? 'block' : 'none' }}
                >
                    <BsArrowUpCircle />
                </button>

            </div>
        </div>

    );
};

export default UnitTestsPage;
