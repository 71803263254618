import React, { useState, useEffect } from 'react';
import './signIn.css'
import { register } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { BiLogIn } from 'react-icons/bi';



const RegisterForm = (props) => {
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [trial, setTrial] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch();
    const userRegister = useSelector((state) => state.userRegister);
    const { userInfo, loading, error } = userRegister;


    /*  const submitHandler = (e) => {
          e.preventDefault();
          if (password !== confirmPassword) {
              alert('Password and confirm password are not match');
          } else {
              dispatch(register(email, password));
              //setTimeout(() => props.history.push('profile'), 500);
          }
      };
      useEffect(() => {
          if (userInfo) {
              // props.history.push(redirect);
          }
      }, [props.history, userInfo]);*/

    const navigate = useNavigate();


    const submitHandler = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('/api/register', {
                firstName,
                lastName,
                password,
                email,
                trial
            });

            // Stocker les informations de l'utilisateur dans le stockage local
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            toast.success('Profile successfully created !');
            setTimeout(() =>
                navigate("/subscriptionsScreen"), 1500);
            // Traiter la réponse du serveur
        } catch (error) {
            // Gérer les erreurs
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        }
    };


    return (
        <form className='formSignIn' onSubmit={submitHandler}>
            <ToastContainer position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <h2>Register</h2>
            <div>
                <label htmlFor="firstName">First Name</label>
                <input
                    required
                    type="text"
                    id="firstName"
                    value={firstName}
                    maxlength="256"
                    pattern="^[a-zA-Z0-9\- ]*$"
                    onChange={(event) => setFirstname(event.target.value)}
                />
            </div>
            <div>
                <label htmlFor="lastName">Last Name</label>
                <input
                    required
                    type="text"
                    id="lastName"
                    value={lastName}
                    maxlength="256"
                    pattern="^[a-zA-Z0-9\- ]*$"
                    onChange={(event) => setLastname(event.target.value)}
                />
            </div>
            <div>
                <label htmlFor="password">Password</label>
                <input
                    required
                    type="password"
                    id="password"
                    value={password}
                    maxlength="256"
                    pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$'
                    onChange={(event) => setPassword(event.target.value)}
                />
            </div>
            <div>
                <label htmlFor="confirmPassword">Confirm password</label>
                <input
                    required
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$'
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    maxlength="256"
                    minlength="8"
                    placeholder='8 char min, Maj, Min, Num'
                />
            </div>
            <div>
                <label htmlFor="email">Email</label>
                <input
                    required
                    type="email"
                    id="email"
                    value={email}
                    maxlength="256"
                    onChange={(event) => setEmail(event.target.value)}
                />
            </div>
            <br/>
            <button className='submitLogin' type="submit">Create and Next</button>
            <Link className='registerLink' to='/login'>
                <BiLogIn className='registerLinkIcon' />Login
            </Link>
        </form>
    );
};

export default RegisterForm;
