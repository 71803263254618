import {
    FETCH_LICENSES_REQUEST,
    FETCH_LICENSES_SUCCESS,
    FETCH_LICENSES_FAILURE,
    CREATE_LICENSE_REQUEST,
    CREATE_LICENSE_SUCCESS,
    CREATE_LICENSE_FAILURE,
    FETCH_ACTIVE_LICENSES_REQUEST,
    FETCH_ACTIVE_LICENSES_SUCCESS,
    FETCH_ACTIVE_LICENSES_FAILURE
  } from '../actions/licensesActions';
  
  // Initial state
  const initialState = {
    licenses: [],
    activeLicenses: [],
    loading: false,
    error: null
  };
  
  // Reducer function
  const licensesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_LICENSES_REQUEST:
      case CREATE_LICENSE_REQUEST:
      case FETCH_ACTIVE_LICENSES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case FETCH_LICENSES_SUCCESS:
        return {
          ...state,
          loading: false,
          licenses: action.payload
        };
      case FETCH_LICENSES_FAILURE:
      case CREATE_LICENSE_FAILURE:
      case FETCH_ACTIVE_LICENSES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case CREATE_LICENSE_SUCCESS:
        return {
          ...state,
          loading: false,
          activeLicenses: [...state.activeLicenses, action.payload]
        };
      case FETCH_ACTIVE_LICENSES_SUCCESS:
        return {
          ...state,
          loading: false,
          activeLicenses: action.payload
        };
      default:
        return state;
    }
  };
  
  export default licensesReducer;