import React from 'react';
import { useParams } from 'react-router-dom';
import ScrollToTopButton from './ScrollToTopBtn';
import BackToPage from './BackToPage';

const AgileTest = () => {



    return (
        <div>
            <BackToPage />
            <div className="course-details">

                <h2><img className='robotIcon jello-vertical' src='../../robot002Tiny.png' alt='robot' />  Advanced Agile Testing Techniques <img className='robotIcon jello-vertical' src='../../robot002Tiny.png' alt='robot' /></h2>
                <p className="description"></p>
                <div className="meta">
                    <img className='robotIcon jello-vertical' src='../../kawaii001Tiny.png' alt='robot' />
                    <div className="author">Auteur: JC Jamet</div>
                    <div className="duration">Reading time: 20 min </div>
                    <img className='robotIcon jello-vertical' src='../../star001.png' alt='robot' />
                </div>
                <ScrollToTopButton />

                {/* Table of Contents */}
                <div className="table-of-contents">
                    <h3>Table of Contents</h3>
                    <ul>
                        <li><a href="#chapter-11">Chapter 11: Performance and Load Testing / Security Testing</a></li>
                        <li className='tableCotent'>
                            <ul>
                                <li><a href="#chapter-11.2">Chapter 11.2: Security Testing in Agile</a></li>
                                <li><a href="#chapter-11.3">Chapter 11.3: Test-Driven Development (TDD) and Behavior-Driven Development (BDD)</a></li>
                            </ul>
                        </li>
                        <li><a href="#chapter-12">Chapter 12: Test Metrics and Reporting</a></li>
                        <li><a href="#chapter-13">Chapter 13: Test Automation Frameworks</a></li>
                        <li><a href="#chapter-14">Chapter 14: Agile Testing Challenges and Solutions</a></li>
                        {/* ... Add more chapters as needed */}
                    </ul>
                </div>

                ____________________

                <div>
                    <h2 id="chapter-11">Chapter 11: Advanced Agile Testing Performance and Load Testing</h2>

                    <h3>Section 11.1: Performance and Load Testing</h3>
                    <p>
                        In this section, we will explore advanced techniques for conducting performance and load testing in an agile context.
                        These techniques ensure that your application can handle the expected load and perform optimally under various conditions.
                    </p>

                    <h4>Subsection 11.1.1: Importance of Performance Testing</h4>
                    <ul>
                        <li>Understanding the impact of poor application performance on user experience and business reputation.</li>
                        <li>
                            Real-world example: A popular e-commerce website experienced slow response times during a holiday sale, leading to frustrated users and lost revenue.
                        </li>
                    </ul>

                    <h4>Subsection 11.1.2: Performance Testing Tools and Types</h4>
                    <ul>
                        <li>Introduction to performance testing tools such as Apache JMeter, Gatling, and locust.io.</li>
                        <li>Differentiating between load testing, stress testing, and endurance testing.</li>
                        <li>
                            Real-world example: Using JMeter to simulate concurrent user traffic on a video streaming platform to determine its ability to handle high demand.
                        </li>
                    </ul>

                    <h4>Subsection 11.1.3: Identifying Performance Bottlenecks</h4>
                    <ul>
                        <li>Strategies for identifying performance bottlenecks, including analyzing response times, server resource utilization, and database performance.</li>
                        <li>
                            Real-world example: Identifying that slow response times were caused by inefficient database queries in a customer relationship management (CRM) application.
                        </li>
                    </ul>

                    <h4>Subsection 11.1.4: Performance Optimization and Scalability</h4>
                    <ul>
                        <li>Techniques for optimizing application performance, including caching, database indexing, and code profiling.</li>
                        <li>Discussing horizontal and vertical scalability to ensure the application can handle increased loads.</li>
                        <li>
                            Real-world example: Implementing caching mechanisms in a content-heavy news website to reduce load times and server load during peak traffic.
                        </li>
                    </ul>
                    <p>
                        By mastering these advanced agile testing techniques, you'll be equipped to handle performance challenges, ensure security,
                        and contribute to the overall quality of the software in complex and dynamic agile development environments.
                    </p>
                    {/* Repeat the above structure for Section 11.2 and Subsections 11.2.1 to 11.2.4 */}
                    <div>
                        <h3 id='chapter-11.2'>Section 11.2: Security Testing in Agile</h3>

                        <h4>Subsection 11.2.1: The Significance of Security Testing</h4>
                        <p>
                            In the healthcare sector, security breaches can lead to severe consequences. For instance, a breach in a patient records management
                            application could expose sensitive medical history and personal information. Such incidents can result in regulatory fines, lawsuits,
                            and loss of patient trust. Security testing is vital to identify vulnerabilities and ensure the protection of sensitive data.
                        </p>

                        <h4>Subsection 11.2.2: Types of Security Testing</h4>
                        <p>
                            Consider a scenario where a financial application stores user financial data. Conducting a vulnerability assessment involves scanning
                            the application for potential vulnerabilities, such as weak authentication mechanisms or unsecured APIs. Penetration testing takes it
                            a step further by actively attempting to exploit these vulnerabilities, simulating real-world attacks to uncover weaknesses that could
                            be exploited by malicious hackers.
                        </p>

                        <h4>Subsection 11.2.3: Security Testing Tools and Techniques</h4>
                        <p>
                            In the context of an e-commerce website, security testing tools like Burp Suite can be used to identify and mitigate common vulnerabilities
                            such as SQL injection. By crafting malicious SQL queries, testers can check if the application is properly handling user inputs, preventing
                            potential database breaches.
                        </p>

                        <h4>Subsection 11.2.4: Continuous Security Testing</h4>
                        <p>
                            Imagine a banking application that undergoes continuous integration. Integrating security scans into the pipeline ensures that each code
                            change is scanned for vulnerabilities. If a developer unknowingly introduces a security flaw, the automated scan will catch it early,
                            allowing the team to address it before deployment. This prevents vulnerabilities from making their way into production and safeguards user data.
                        </p>

                        <p>
                            By incorporating advanced performance and security testing techniques into your agile testing practices, you'll contribute to delivering
                            high-quality software that meets user expectations, performs well under stress, and remains secure against potential threats.
                        </p>
                    </div>
                    <div>
                        <h3 id='chapter-11.3'>Section 11.3: Test-Driven Development (TDD) and Behavior-Driven Development (BDD)</h3>

                        <h4>Subsection 11.3.1: Introduction to TDD and BDD</h4>
                        <p>
                            Test-Driven Development (TDD) and Behavior-Driven Development (BDD) are agile practices that emphasize writing tests before writing code.
                            TDD focuses on unit testing, while BDD extends this concept to a higher level by incorporating collaboration between developers, testers,
                            and non-technical stakeholders to define behavior in plain language.
                        </p>

                        <h4>Subsection 11.3.2: Test-Driven Development (TDD)</h4>
                        <p>
                            Imagine you are developing a calculator application. Using TDD, you would start by writing a failing test that describes the functionality
                            you're about to implement, such as addition. The test might assert that adding 2 and 3 should result in 5. Then, you write the minimal
                            code necessary to make the test pass. Once the test passes, you refactor the code for readability and maintainability. This approach ensures
                            that your code meets the specified requirements and prevents regressions.
                        </p>

                        <h4>Subsection 11.3.3: Behavior-Driven Development (BDD)</h4>
                        <p>
                            Consider an e-commerce platform where customers can place orders. Using BDD, you collaborate with product owners and stakeholders to define
                            behavior using a structured language like Gherkin. For instance, a feature description might be: "As a customer, I want to be able to place
                            an order so that I can purchase products." This is accompanied by specific scenarios like "Given I have selected items in my cart, when I
                            proceed to checkout, then I should receive an order confirmation."
                        </p>

                        <h4>Subsection 11.3.4: Tools for TDD and BDD</h4>
                        <p>
                            In a TDD scenario, you might use a testing framework like JUnit (Java) or pytest (Python) to write and execute unit tests. For BDD, tools like
                            Cucumber or SpecFlow allow you to write human-readable scenarios in a Given-When-Then format and automate their execution.
                        </p>

                        <h4>Subsection 11.3.5: Benefits of TDD and BDD</h4>
                        <ul>
                            <li>Early Detection of Issues: By writing tests first, you catch bugs before they propagate into the codebase.</li>
                            <li>Clear Requirements: BDD scenarios provide clear and shared understanding of expected behavior.</li>
                            <li>Collaboration: BDD encourages collaboration among developers, testers, and stakeholders.</li>
                        </ul>

                        <h4>Subsection 11.3.6: Challenges and Best Practices</h4>
                        <p>
                            Challenges of TDD and BDD include maintaining a balance between test coverage and development speed. Ensuring meaningful tests that cover
                            essential scenarios while avoiding excessive testing requires experience and judgment.
                        </p>

                        <h4>Subsection 11.3.7: Real-World Example</h4>
                        <p>
                            Imagine you're developing a login feature for a mobile app. In TDD, you start by writing a test that asserts a user with correct credentials
                            can log in successfully. You then write the code to implement the login functionality. For BDD, you collaborate with stakeholders to define
                            scenarios like "Given a registered user, when they provide valid credentials, then they should be logged into the app."
                        </p>
                        <p>
                            Both approaches ensure that the developed feature meets requirements, but BDD emphasizes collaboration and shared understanding.
                        </p>

                        <p>
                            By incorporating TDD and BDD into your agile testing practices, you ensure code quality, facilitate communication, and deliver software that
                            aligns with stakeholder expectations.
                        </p>
                    </div>
                    ____________________

                    <div>
                        <h3 id="chapter-12">Chapter 12: Test Metrics and Reporting</h3>

                        <h4>Section 12.1: Metrics for Agile Testing</h4>
                        <p>
                            In agile testing, metrics play a crucial role in assessing the effectiveness of your testing efforts and identifying areas for improvement.
                            Some key metrics include:
                        </p>
                        <ul>
                            <li>Defect Density: Calculated by dividing the number of defects by the size of the codebase. This metric helps you understand the quality of your code.</li>
                            <li>Test Coverage: Measures the percentage of code covered by your tests. It indicates the areas that have been thoroughly tested and those that need more attention.</li>
                            <li>Code Churn: Represents the rate at which code is added, modified, or deleted. High code churn can impact stability and quality.</li>
                            <li>Velocity: Commonly used in Agile methodologies, velocity measures the amount of work completed in a sprint. It helps in planning and forecasting.</li>
                        </ul>
                        <p>
                            By analyzing these metrics over time, you can make informed decisions to enhance the testing process and deliver higher-quality software.
                        </p>

                        <h4>Section 12.2: Reporting and Communication</h4>
                        <p>
                            Effective reporting and communication are essential for keeping all stakeholders informed about the testing progress and the quality of the software.
                            Some best practices for reporting include:
                        </p>
                        <ul>
                            <li>Crafting Meaningful Test Reports: Tailor your reports to the audience. Developers might need technical details, while managers and clients prefer summaries.</li>
                            <li>Clear Communication: Articulate the progress of testing, any issues encountered, and the overall quality status in a concise and understandable manner.</li>
                            <li>Visualizations and Insights: Incorporate charts, graphs, and visual representations of data to provide a quick overview and facilitate understanding.</li>
                        </ul>
                        <p>
                            For example, you could create a test report with a summary of test execution results, a graphical representation of test coverage, and a breakdown of critical defects.
                            Such reports help stakeholders make informed decisions and maintain confidence in the quality of the software.
                        </p>
                    </div>
                    ____________________

                    <div>
                        <h3 id="chapter-13">Chapter 13: Test Automation Frameworks</h3>

                        <h4>Section 13.1: Creating a Test Automation Strategy</h4>
                        <p>
                            A successful test automation strategy ensures effective agile testing. For instance, consider an e-commerce application. Your automation strategy might include:
                        </p>
                        <ul>
                            <li>Automating login and registration flows to ensure smooth user onboarding.</li>
                            <li>Automating checkout and payment processes to validate critical business transactions.</li>
                            <li>Automating search functionality to verify accurate product results.</li>
                        </ul>
                        <p>
                            By selecting test cases wisely and focusing on high-priority areas, your strategy contributes to continuous delivery and reliable software.
                        </p>

                        <h4>Section 13.2: Building Modular and Maintainable Test Automation Frameworks</h4>
                        <p>
                            Let's explore an example of the Page Object Model (POM) in JavaScript for a web application. In POM, each page of the application is represented as a class, encapsulating its elements and actions:
                        </p>
                        <pre>
                            {`
class LoginPage {
  constructor() {
    this.usernameInput = element(by.id('username'));
    this.passwordInput = element(by.id('password'));
    this.loginButton = element(by.id('login-button'));
  }

  login(username, password) {
    this.usernameInput.sendKeys(username);
    this.passwordInput.sendKeys(password);
    this.loginButton.click();
  }
}

module.exports = new LoginPage();
        `}
                        </pre>
                        <p>
                            This allows easy maintenance and updates whenever the UI changes, without affecting the test scripts directly.
                        </p>
                        <pre>
                            {`
const LoginPage = require('./LoginPage'); // Import the Page Object for Login page

const HomePage = require('./HomePage');   // Import the Page Object for Home page

describe('Login and Navigation Test', () => {
  it('should login and navigate to the home page', () => {
    browser.get('https://example.com'); // Navigate to the application URL

    LoginPage.login('username', 'password'); // Use the login method from LoginPage

    expect(HomePage.isDisplayed()).toBe(true); // Verify that the home page is displayed
  });
});
`}
                        </pre>

                        <p>
                            This is an example of POM implementation in a Cypress test. The principle remains the same regardless of the testing tool, whether it's Selenium or any other.
                        </p>

                        <h4>Section 13.3: Continuous Integration for Test Automation</h4>
                        <p>
                            Integrating test automation with Jenkins is essential for quick feedback. Below is an example of a Jenkins pipeline script for running automated tests:
                        </p>
                        <pre>
                            {`
pipeline {
  agent any
  
  stages {
    stage('Checkout') {
      steps {
        checkout scm
      }
    }
    
    stage('Install Dependencies') {
      steps {
        sh 'npm install'
      }
    }
    
    stage('Run Tests') {
      steps {
        sh 'npm test'
      }
    }
  }
}
        `}
                        </pre>
                        <p>
                            This pipeline checks out the code, installs project dependencies, and then runs the automated tests using a simple npm command.
                        </p>
                    </div>
                    ____________________
                    <div className="course-content">
                        <h2 id="chapter-14">Chapter 14: Agile Testing Challenges and Solutions</h2>
                        <h3>Section 14.1: Addressing Distributed Teams and Time Zone Challenges</h3>
                        <p>
                            Strategies for effective testing in distributed agile teams.
                            Coordinating testing efforts across different time zones.
                            Leveraging communication tools and clear documentation.
                        </p>

                        <h3>Section 14.2: Handling Legacy Systems and Technical Debt</h3>
                        <p>
                            Testing challenges when dealing with legacy code and technical debt.
                            Strategies for refactoring and improving the testability of legacy systems.
                        </p>

                        <h3>Section 14.3: Integrating DevOps and Continuous Delivery</h3>
                        <p>
                            Understanding the integration of agile testing into DevOps practices.
                            Automating deployment pipelines and incorporating testing at each stage.
                            Achieving seamless continuous delivery with minimal risk.
                        </p>

                        <p>
                            This advanced course builds upon the fundamentals covered in the previous course, delving into more complex and specialized topics related to agile testing.
                            It equips participants with the knowledge and skills required to tackle challenging scenarios and contribute to the successful implementation of agile testing practices in various real-world contexts.
                        </p>
                    </div>

                </div>

                ____________________

                <div className='happyRobot'>
                    <h4>Congratulations, you have now explored all chapters of this course on advenced agile testing !</h4>

                    <div className='happyRobot2'>  <img className='happyRobot001 vibrate-1' src='../../happy_robot.png' alt='congrat_robot' />
                        <img className='iconKawaii puff-out-center' src='../../bubble001.png' alt='congrat_robot' />
                        <img className='iconKawaii  puff-out-bl' src='../../star001.png' alt='congrat_robot' />

                        <img className='iconKawaii puff-out-center2' src='../../heart001.png' alt='congrat_robot' />
                        <img className='iconKawaii puff-out-center3' src='../../bubble001.png' alt='congrat_robot' />
                        <img className='iconKawaii puff-out-center2' src='../../heart001.png' alt='congrat_robot' />
                        <img className='iconKawaii  puff-out-br' src='../../star001.png' alt='congrat_robot' />
                        <img className='iconKawaii puff-out-center2' src='../../bubble001.png' alt='congrat_robot' />
                        <img className='happyRobot001 vibrate-1' src='../../happy_robotReverse.png' alt='congrat_robot' /></div>
                </div>

            </div >
        </div>
    );
};

export default AgileTest;
