import React, { useState, useEffect } from 'react';
import '../App.css';
import { BsArrowUpCircle } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { BiSolidLeftArrow, BiSolidRightArrow } from 'react-icons/bi';
import { MdSchool } from 'react-icons/md';
import { FiRewind } from 'react-icons/fi';

const TestCasesPage = () => {
    const [formData, setFormData] = useState({
        context: '',
        asA: '',
        iWant: '',
        soThat: '',
        language: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState('');

    /* window.addEventListener('load', () => {
         const element = document.getElementById('callAPIButton');
         element.scrollIntoView({ behavior: 'smooth' });
 
     });*/

    const copyResponseToClipboard = () => {
        const responseContainer = document.getElementById('responseContainer');

        const textToCopy = responseContainer.innerText;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success('Response copied to clipboard :)');
            })
            .catch(error => {
                toast.error('Error copying to clipboard :', error);
            });
    };

    const [language, setLanguage] = useState('français');
    const [isBubbleVisible, setIsBubbleVisible] = useState(false);
    const [bubbleTextIndex, setBubbleTextIndex] = useState(0);
    const bubbleTexts = [
        "Hello there!",
        "I'm AI-Can-learn. I will teach you how to use this cool tool !",
        "But I'm learning for the moment... come back later !"
    ];

    const handleBubbleClick = () => {
        const educate = document.getElementById('educate');
        const clickMe = document.getElementById('clickMe');
        const arrow = document.getElementById('arrow');

        educate.classList.add('hide');
        clickMe.classList.add('hide');
        arrow.classList.add('show');
        setIsBubbleVisible(!isBubbleVisible);

    };

    const handleArrowClick = () => {
        setBubbleTextIndex((bubbleTextIndex + 1) % bubbleTexts.length);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'language') {
            setLanguage(value); // Met à jour l'état avec la langue sélectionnée
            // Vous pouvez ajouter ici la logique pour effectuer des actions spécifiques en fonction de la langue sélectionnée
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value,
                language: setLanguage(value)
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const spinner = document.getElementById('spinner');
        spinner.classList.add('show');

        const button = document.getElementById('callAPIButton');
        button.classList.add('hide');
        const copyButton = document.getElementById('copyButton');
        const btnBackToTop = document.getElementById('btnBackToTop');



        const dataToSend = {
            context: formData.context,
            asA: formData.asA,
            iWant: formData.iWant,
            soThat: formData.soThat,
            language: language
        };

        try {
            const response = await axios.post('/api/generate-response-test-cases', dataToSend); // Send the form data to the backend endpoint
            const generatedResponse = response.data.response;
            setResponse(generatedResponse);
        } catch (error) {
            toast.error('Error calling backend API:', error);
        } finally {
            spinner.classList.remove('show');
            copyButton.classList.add('show');
            button.classList.remove('hide');
            btnBackToTop.classList.add('show');
            copyButton.scrollIntoView({ behavior: 'smooth' });
            setIsLoading(false);
        };
    };

    const [showBackToTop, setShowBackToTop] = useState(false);

    // Function to handle scroll event and toggle the visibility of the Back to Top button
    const handleScroll = () => {
        const responseContainer = document.getElementById('responseContainer');
        if (responseContainer) {
            setShowBackToTop(window.scrollY > 0);
        }
    };

    // Add scroll event listener when the component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div>
            <ToastContainer position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />

            {/* Add the bubble */}
            <div className='terminator'>
                <div className="bubble-iconReverse bounce-in-right" onClick={handleBubbleClick}>
                    <div id='clickMe' className='bubbleIconTxtReverse bounce-in-right'>Click me !</div>
                    <MdSchool id='educate' className='bubbleIconReverse bounce-in-right' />
                    <BiSolidLeftArrow id='arrow' className='bubbleIconReverse bounce-in-right' />
                </div>


                {isBubbleVisible && (
                    <div className="bubble-container">
                        <div className="bubbleReverse">
                            <p className="dialogue-text">{bubbleTexts[bubbleTextIndex]}</p>
                            <div className="arrow-btnReverse" onClick={handleArrowClick}>
                                <FiRewind />
                            </div>
                        </div>
                    </div>
                )}
                <div class="moving-object-info001Reverse bounce-in-right">
                    <img
                        src="../../wall_ai002reverse.png"
                        alt="Wall-AI Robot"
                    /></div>
            </div>
            {/* End the bubble */}

            <form className='form002 slide-in-bottom' onSubmit={handleSubmit}>
                <label className='labelColor'>
                    User Story context :
                </label>
                <textarea
                    rows="6" cols="62"
                    type="text"
                    name="context"
                    value={formData.context}
                    onChange={handleChange}
                    required
                    placeholder='[Context of the user story.]'
                />
                <br />
                <label className='labelColor'>
                    As a ...</label>
                <textarea
                    rows="4" cols="42"
                    type="text"
                    name="asA"
                    value={formData.asA}
                    onChange={handleChange}
                    required
                    placeholder='[type of user]'
                />

                <br />
                <label className='labelColor'>
                    I want...</label>
                <textarea
                    rows="5" cols="42"
                    type="text"
                    name="iWant"
                    value={formData.iWant}
                    onChange={handleChange}
                    required
                    placeholder='[an action or feature]'
                />
                <br />
                <label className='labelColor'>
                    So that....</label>
                <textarea
                    rows="5" cols="42"
                    type="text"
                    name="soThat"
                    value={formData.soThat}
                    onChange={handleChange}
                    required
                    placeholder='[a benefit or value]'
                />
                <br />
                <label className='labelColor'>
                    My response will be in the language...</label>
                <select id="langDropdown" name="language" value={language} onChange={handleChange}>
                    <option value="français">Français</option>
                    <option value="english">English</option>
                </select>
                <br />
                <button type="submit" id='callAPIButton' className='callAPIButton' disabled={isLoading}>
                    {isLoading ? 'Generating...' : 'Generate'}
                </button>
            </form>
            <br />
            <div id="spinner"></div>
            {isLoading && <div id="spinner"></div>}
            {!isLoading && response && (
                <pre id="responseContainer">{response}
                </pre>
            )}
            <h3 className='importResponseTxt'> Copy the tests and paste it into your application, do not forget to replace the variables if the generated ones are examples.
            </h3>
            <div>
                <button id="copyButton" className="copyButton" onClick={copyResponseToClipboard}>Copy to clipboard</button>
                <button
                    id="btnBackToTop"
                    className='btnBackToTop'
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    style={{ display: showBackToTop ? 'block' : 'none' }}
                >
                    <BsArrowUpCircle />
                </button>

            </div>
        </div>

    );
};

export default TestCasesPage;
