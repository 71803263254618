import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";


const MinifierForm = () => {
  const [inputCode, setInputCode] = useState("");
  const [outputCode, setOutputCode] = useState("");
  const [isMinified, setIsMinified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    setInputCode(e.target.value);
  };

  const handleMinify = async () => {
    try {
      const response = await axios.post("/api/minify", { code: inputCode });
      setOutputCode(response.data);
      setIsMinified(true);
      setErrorMessage(""); // Clear the error message if the minification is successful
      const copyButton = document.getElementById('copyButton');
      copyButton.classList.add('show');
    } catch (error) {
      console.error("Error while minifying the code:", error);
      setErrorMessage("Error while minifying the code");
    }
  };

  const handleDeMinify = () => {
    setOutputCode(inputCode);
    setIsMinified(false);
    setErrorMessage(""); // Clear the error message when switching to de-minification
  };


  const copyResponseToClipboard = () => {
    const responseContainer = document.getElementById('responseContainerMimif');
    responseContainer.select();

    try {
      document.execCommand('copy');
      toast.success('Copié dans le presse-papier :)');
    } catch (error) {
      console.error('Erreur lors de la copie dans le presse-papier :', error);
    }
  };


  return (
    <div className="form002">
      <ToastContainer position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <h1>Mimification de code Javascript / NodeJS</h1>
      <div>
        <h2>Code d'origine</h2>
        <textarea
          value={inputCode}
          onChange={handleInputChange}
          rows={10}
          cols={80}
        />
      </div>
      <div>
        <button className='submitMimifier' onClick={handleMinify}>Mimifier</button>
        {/* <button className='submitMimifier' onClick={handleDeMinify}>Déminifier</button> */}
      </div>
      <div>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <h2>{isMinified ? "Code mimifié" : "Code démimifié"}</h2>
        <textarea id='responseContainerMimif' value={outputCode} rows={10} cols={80} />
        <button id="copyButton" className="copyButton" onClick={copyResponseToClipboard}>Copy to clipboard</button>
      </div>
    </div>
  );
};

export default MinifierForm;
