import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { TfiReload } from 'react-icons/tfi';
import { FiRefreshCw } from 'react-icons/fi';
import { PiSmiley } from 'react-icons/pi';
import { BiHappyBeaming } from 'react-icons/bi';
import { BsEmojiHeartEyes } from 'react-icons/bs';
import SubscriptionCancelButton from './SubscriptionCancelButton';
import { fetchSubscriptionValidity } from '../actions/subscriptionActions';
import { useDispatch } from 'react-redux';
// frontend/src/config.js

const SubscriptionScreen = () => {
    const [isMonthly, setIsMonthly] = useState(true);
    const [isFreeTrial, setIsFreeTrial] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    //const [selectedPrice, setPrice] = useState('');
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const _id = user._id;
    const [isValidSubscription, setIsValidSubscription] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const userInfos = JSON.parse(localStorage.getItem('userInfo'));

    const [subscriptionID, setSubscriptionID] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isRenewDate, setRenewDate] = useState('');
    const navigate = useNavigate();
    const paypalRef = useRef();
   
    const paypalUrl = process.env.REACT_APP_PAYPAL_URL;
    const paypalPLAN001 = process.env.REACT_APP_PAYPAL_PLANMENSUEL;
    const paypalPLAN002 = process.env.REACT_APP_PAYPAL_PLANANNUEL;

    const updateUserInfos = async () => {
        const user = JSON.parse(localStorage.getItem('userInfo'));

        try {
            const response = await axios.get('/api/profile/licenses', {
                params: { _id: user._id },
                headers: { Authorization: `Bearer ${user?.token}` }
            });
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            toast.success('Congratulations, you are ready to test!');
        } catch (error) {
            // Gérer les erreurs
            if (error.response && error.response.data.message) {
                alert(error.response.data.message);
            } else {
                alert(error.message);
            }
        }
    };

    useEffect(() => {
        // Chargez le script PayPal
        const script = document.createElement('script');
        script.src = paypalUrl;
        script.addEventListener('load', () => {
            // Une fois le script chargé, créez les boutons d'abonnement
            window.paypal.Buttons({
                style: {
                    shape: 'pill',
                    color: 'blue',
                    layout: 'vertical',
                    label: 'subscribe'
                },
                createSubscription: (data, actions) => {
                    return actions.subscription.create({
                        plan_id: isMonthly ? paypalPLAN001 : paypalPLAN002 // Remplacez par vos ID de plan d'abonnement PayPal
                    });
                },
                onApprove: (data, actions) => {
                    // Gérez l'approbation de l'abonnement ici
                    console.log('Approved subscription:', data);
                    console.log('subscription ID:', data.subscriptionID);
                    //setSubscriptionID(data.subscriptionID);
                    handleSubscribe(user.email, data.subscriptionID);
                    toast.success('Approved subscription:', data);
                },
                onCancel: (data, actions) => {
                    // Gérez l'annulation de l'abonnement ici
                    console.log('Subscription cancelled:', data);
                    toast.error('Subscription cancelled:', data);
                },
                onError: (err) => {
                    // Gérez les erreurs ici
                    console.error('Error creating subscription:', err);
                    toast.error('Error creating subscription:', err);
                },
                // Ajoutez la fonction onCancelSubscription pour gérer l'annulation de la souscription
                onCancelSubscription: (data, actions) => {
                    return actions.subscription.cancel({
                        plan_id: isMonthly ? paypalPLAN001 : paypalPLAN002, // Remplacez par vos ID de plan d'abonnement PayPal
                        subscription_id: subscriptionID
                    }).then(function () {
                        // Gérez l'annulation de la souscription ici
                        console.log('Subscription cancelled:', data);
                        toast.error('Subscription cancelled:', data);
                    });
                }
            }).render(paypalRef.current);
        });
        document.body.appendChild(script);
    }, [isMonthly]);


    const handleClick = () => {
        window.location.reload();
    };

    const handleSubscribe = async (email, subscriptionID) => {
        try {
            const price = isFreeTrial ? 0 : isMonthly ? 10 : 100;
            const autoRenew = !isFreeTrial;
            const type = isFreeTrial ? 'Free' : isMonthly ? 'Monthly' : 'Yearly';
            //console.log('Subscription ID:', subscriptionID);
            const response = await fetch('/api/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ type, email, price, autoRenew, isMonthly, subscriptionID })
            });

            const data = await response.json();

            if (response.ok) {
                setResponseMessage(data.message);
                setErrorMessage('');
                updateUserInfos();
            } else {
                setResponseMessage('');
                setErrorMessage(data.error);
            }
        } catch (error) {
            console.error('Erreur lors de la souscription:', error);
            setResponseMessage('');
            setErrorMessage('Une erreur est survenue lors de la souscription');
        } finally {
            const subscribeBtn = document.getElementById('submitBtnSub');
            const reloadBtn = document.getElementById('reloadBtn');
            if (subscribeBtn) {
                subscribeBtn.classList.add('hide');
                reloadBtn.classList.add('show');
            }
        }
    };


    const handleCancelSubscription = async () => {
        try {
            const actions = window.paypal?.Buttons?.driver('react').actions;
            if (actions) {
                actions.subscription.cancel().then(function () {
                    // Gérez l'annulation de la souscription ici
                    console.log('Subscription cancelled:', subscriptionID);
                    toast.error('Subscription cancelled:', subscriptionID);
                });
            }
        } catch (error) {
            console.error('An error occurred while cancelling the subscription:', error);
            setResponseMessage('');
            setErrorMessage('An error occurred while cancelling the subscription');
        } finally {
            const cancelBtn = document.getElementById('cancelBtn');
            if (cancelBtn) {
                cancelBtn.classList.add('hide');
            }
        }
    };

    const dispatch = useDispatch();
    useEffect(() => {
        if (userInfos) {
          dispatch(fetchSubscriptionValidity(userInfos));
        }
      }, [dispatch, userInfos]);
  

    const handleFreeTrialChange = (value) => {
        setIsFreeTrial(value);
        if (value) {
            setIsMonthly(false);
            console.log('isFreeTrial', value);
            const buttonPaypal = document.getElementById('paypalBtn');
            const buttonFreeTrial = document.getElementById('submitBtnSub');
            buttonFreeTrial.classList.add('show');
            buttonFreeTrial.classList.remove('hide');
            buttonPaypal.classList.add('hide');
            buttonPaypal.classList.remove('show');
        } else {
            const buttonPaypal = document.getElementById('paypalBtn');
            const buttonFreeTrial = document.getElementById('submitBtnSub');
            buttonFreeTrial.classList.add('hide');
            buttonFreeTrial.classList.remove('show');
            buttonPaypal.classList.add('show');
            buttonPaypal.classList.remove('hide');
        }
    };

    return (
        <div className="formSubscribe">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <h1>Subscriptions</h1>
            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={isFreeTrial}
                        onChange={(e) => handleFreeTrialChange(e.target.checked)}
                    />
                    Free trial <PiSmiley size={30} />
                </label>
            </div>
            {!isFreeTrial && (
                <>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={isMonthly}
                                onChange={() => setIsMonthly(!isMonthly)}
                            />
                            Monthly, 9.99 € (to be defined :))<BiHappyBeaming size={30} />
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={!isMonthly}
                                onChange={() => setIsMonthly(!isMonthly)}
                            />
                            Annual, 99.99 € (to be defined :))<BsEmojiHeartEyes size={30} />
                        </label>
                    </div>
                </>
            )}
            <button
                id="submitBtnSub"
                className="submitBtnSub hide"
                onClick={() => handleSubscribe(user.email)}
            >
                Start Free Trial
            </button>
            <div id="paypalBtn" className="paypalBtn" ref={paypalRef} />
            <FiRefreshCw
                id="reloadBtn"
                className="reloadBtn rotate-center"
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            />
            {responseMessage && (
                <p>
                    {responseMessage + ' Date de renouvellement: ' + isRenewDate}
                </p>
            )}
            {errorMessage && <p>{errorMessage}</p>}
            {subscriptionID && (
                <SubscriptionCancelButton
                    subscriptionID={subscriptionID}
                    userId={userInfos._id}
                />

            )}
        </div>
    );
};

export default SubscriptionScreen;
