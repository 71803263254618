import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { createLicense } from '../actions/licensesActions';

const SubscriptionPage = () => {
  const [selectedLicense, setSelectedLicense] = useState('');
  const dispatch = useDispatch();
  const licenses = useSelector((state) => state.licenses);

  const handleLicenseChange = (event) => {
    setSelectedLicense(event.target.value);
  };

  const handleSubscribe = () => {
    if (selectedLicense) {
      dispatch(createLicense(selectedLicense))
        .then(() => {
          Navigate('/payment');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div>
      <h2>Choose a license :</h2>
      <select value={selectedLicense} onChange={handleLicenseChange}>
        <option value="">Select a license</option>
        {licenses.map((license) => (
          <option key={license._id} value={license._id}>
            {license.type} - {license.price}€
          </option>
        ))}
      </select>
      <button onClick={handleSubscribe}>Subscribe</button>
    </div>
  );
};

export default SubscriptionPage;