import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import './signIn.css';
import axios from 'axios';
import { LiaAddressCard } from 'react-icons/lia'

const SignInForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const subscription = JSON.parse(localStorage.getItem('subscription'));
    const [validSubscription, setIsValidSubscription] = useState(false);
    const [formattedDate, setIsFormattedDate] = useState(null);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Vérifier si les informations d'utilisateur sont présentes
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo === email) {
            // Utilisateur déjà connecté, rediriger vers la page du profil
            setLoggedIn(true);
            fetchSubscriptionValidity(storedUserInfo);
        } else {
            // Utilisateur non connecté, appeler l'endpoint POST /signin
            signInUser(email, password);
        }
    };

    const getEvents = async () => {
        try {
            const response = await axios.post(`/paypal-webhook`)

            //console.log("response paypal", response);

        } catch (error) {
            console.error('Une erreur est survenue lors de la vérification de l\'abonnement', error);
            setError('Une erreur est survenue lors de la vérification de l\'abonnement');
        }
    }


    const fetchSubscriptionValidity = async (storedUserInfo) => {

        try {
            const response = await axios.get(`/api/isValidSubscription/${storedUserInfo._id}`, {
                headers: { Authorization: `Bearer ${storedUserInfo?.token}` }
            })
            localStorage.setItem('subscription', JSON.stringify(response.data));
            setIsValidSubscription(response.data.isValid);

            const jsonDate = subscription.renewDate; // Votre date au format JSON

            const date = new Date(jsonDate);
            const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
            setIsFormattedDate(formattedDate);
            //console.log(formattedDate); // Résultat : "13/7/2023 15:30:45"

        } catch (error) {
            console.error('Une erreur est survenue lors de la vérification de l\'abonnement', error);
            setError('Une erreur est survenue lors de la vérification de l\'abonnement');
        } finally {
            setLoading(false);
        }

    };


    const reloadAfterDelay = async () => {
        await new Promise((resolve) => setTimeout(resolve, 250));
        // Rechargement de la page pour affichage des icones
        getEvents();
        window.location.reload();
    };


    const signInUser = async (email, password) => {

        try {
            const response = await axios.post('/api/signin', {

                password,
                email
            });
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            setLoggedIn(true,
                reloadAfterDelay())
        } catch (error) {
            // Gérer les erreurs
            if (error.response && error.response.data.message) {
                alert(error.response.data.message);
            } else {
                alert(error.message);
            }
        };

    };

    if (loggedIn) {
        return <Navigate to="/profile" />;
    }

    return (
        <div>
            <h2>Connexion</h2>
            <form className='formSignIn' onSubmit={handleFormSubmit}>
                <div>
                    <label htmlFor="email">Email :</label>
                    <input type="email"
                        id="email"
                        value={email}
                        maxlength="256"
                        onChange={(e) => setEmail(e.target.value)}
                        required />
                </div>
                <div>
                    <label htmlFor="password">Password :</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        maxlength="256"
                        pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$'
                        onChange={(e) => setPassword(e.target.value)}
                        minlength="8"
                        placeholder='8 char min, Maj, Min, Num'
                        required />
                </div>
                <div>
                    <br/>
                    <button className='submitLogin' type="submit">Sign in</button>
                    <Link className='registerLink' to='/registerForm'>
                        <LiaAddressCard className='registerLinkIcon' />Register
                    </Link>
                </div>
            </form>

        </div>
    );
};

export default SignInForm;