import React from 'react';
import { useParams } from 'react-router-dom';
import ScrollToTopButton from './ScrollToTopBtn';
import BackToPage from './BackToPage';

const ApiToolsheet = () => {



    return (
        <div>
            <BackToPage />
            <div className="course-details">

                <h2><img className='robotIcon jello-vertical' src='../../robot002Tiny.png' alt='robot' /> API testing <img className='robotIcon jello-vertical' src='../../robot002Tiny.png' alt='robot' /></h2>
                <p className="description"></p>
                <div className="meta">
                    <img className='robotIcon jello-vertical' src='../../kawaii001Tiny.png' alt='robot' />
                    <div className="author">Auteur: JC Jamet</div>
                    <div className="duration">Reading time: 30 min </div>
                    <img className='robotIcon jello-vertical' src='../../star001.png' alt='robot' />
                </div>
                <ScrollToTopButton />

                {/* Table of Contents */}

                <div className="table-of-contents">
                    <h3>Table of Contents</h3>
                    <ul>
                        <li><a href="#chapter-1">Understand API requirements</a></li>
                        <li className='tableCotent'>
                            <ul>
                                <li><a href="#chapter-2">Specify the API output status</a></li>
                                <li><a href="#chapter-3">Focus on small functional APIs</a></li>
                            </ul>
                        </li>
                        <li><a href="#chapter-4">Create positive and negative tests ( and exotic tests )</a></li>
                        <li><a href="#chapter-5">API Testing Principles</a></li>
                        <li><a href="#chapter-6">API Testing Tips</a></li>
                        {/* ... Add more chapters as needed */}
                    </ul>
                </div>
                {/* Table of Contents end */}


                <div className="api-testing-guide">
                    <h2>How to Start API Testing</h2>
                    <p>There are many different types of tests you can perform on your APIs:
                        Functionality tests focus on ensuring that the API is able to perform its intended functions.
                        Performance tests measure the response times of the API and check for bottlenecks.
                        Security tests assess the vulnerability of the API to attack vectors such as SQL injection and cross-site scripting (XSS).
                        To get started with API testing, you will need to have access to an application with an exposed API. You will also need to choose a method for sending requests to the API (manual or automated), and select a tool or framework for writing your tests (if using automated testing).
                        Once you have these things set up, you can begin writing your test cases and running them against the API.</p>
                    <ol>
                        <h3 id='chapter-1'>Understand API requirements</h3>
                        <p>Before testing your APIs, you need to answer these questions to thoroughly understand the API’s requirements:</p>
                        <li>What is the API’s purpose?</li>
                        <p>Knowing the purpose of the API will set a firm foundation for you to well prepare your test data for input and output. This step also helps you define the verification approach. For example, for some APIs, you will verify the responses against the database; and for some others, it is better to verify the responses against other APIs.</p>
                        <li>What is the workflow of the application; and where is the API in that flow?</li>
                        <p>Generally, APIs of an application are used to manipulate its resources in reading (GET), creating (POST), updating (PUT) and deleting (DELETE). Knowing the purpose of the API will set a firm foundation for you to well prepare your API testing data for input and output.
                            In addition, this step also helps you define the verification approach. For example, for some APIs, you will verify the responses against the database; and for some others, it is better to verify the responses against other APIs.
                            For example, the output of the “Create user” API will be the input of the “Get user” API for verification. The output of the “Get user” API can be used as the input of the “Update user” API, and so on.</p>

                        <h3 id='chapter-2'> Specify the API output status</h3>
                        <p>The most common API output you need to verify in API testing is the response status code.
                            Verifying if the response code equals to 200 or not to decide whether an API testing is passed or failed is familiar to new API testers. This is not a wrong verification. However, it does not reflect all test scenarios of the API.
                            All API response status codes are separated into five classes (or categories) in a global standard. The first digit of the status code defines the class of response. The last two digits do not have any class or categorization role.
                            There are five values for the first digit:
                            1xx (Informational): The request is received and continues to be processed
                            2xx (Successful): The request is successfully received, understood, and accepted
                            3xx (Redirection): Further action needs to be taken to complete the request
                            4xx (Client Error): The request contains the wrong syntax or cannot be fulfilled
                            5xx (Server Error): The server fails to fulfill an apparently valid request
                            However, the actual response status code of an API is specified by the development team that built the API. So as a tester, you need to verify whether:
                            The code follows global standard classes
                            The code is specified in the requirement.</p>

                        <h3 id='chapter-3'> Focus on small functional APIs</h3>
                        <p>In a testing project, there are always some APIs that are simple with only one or two inputs such as login API, get token API, health check API, etc. However, these APIs are necessary and are considered as the “gate” to enter further APIs. Focusing on these APIs before the others will ensure that the API servers, environment, and authentication work properly.
                            You should also avoid testing more than one API in a test case. It is painful if errors occur because you will have to debug the data flow generated by API in a sequence. Keep your testing as simple as possible. There are some cases in which you need to call a series of API to achieve an end-to-end testing flow. However, these tasks should come after all APIs have been individually tested.</p>

                        <li> Organize API endpoints</li>
                        <p>A testing project may have a few or even hundreds of APIs for testing. We highly suggest that you organize them into categories for better test management. It takes one extra step but will significantly help you create test scenarios with high coverage and integration. Take JIRA’s API, for

                            example:


                            APIs in the same category share some common information such as resource type, path, etc. Organizing your tests with the same structures will make your test reusable and extendable with integration flow.</p>

                        <li>Leverage automation capability for API testing</li>
                        <p>Leverage automation capability for your API testing process as much and as early as possible. Here are some significant benefits of automating API tests:
                            Test data and execution history can be saved along with API endpoints. This makes it easier to rerun tests later.
                            API tests are stable and changed with care. An API reflects a business rule of the system. Any change in the API needs an explicit requirement; so testers can always stay alert of any changes and adjust them on time.
                            Test execution is much faster compared to Web UI test
                            API testing is considered black-box testing in which the users send input and get output for verification. Automation with a data-driven approach — i.e. applying different datasets in the same test scenario — can help increase API test coverage
                            Data input and output follow some specific templates or models so that you can create test scripts only once. These test scripts can also be reused throughout the entire testing project.
                            API tests can be performed at the early stage of the software development lifecycle. An automation approach with mocking techniques can help verify API and its integration before the actual API is developed. Hence, the level of dependency within the team is reduced. </p>

                        <li> Choose suitable verification methods</li>
                        <p>While the response status code tells the status of the request, the response body content is what an API returns with the given input.
                            An API response content varies from data types to sizes. The responses can be in plain text, a JSON data structure, an XML document, and more. They can be a simple few-word string (even empty), or a hundred-page JSON/XML file. Hence, it is essential to choose a suitable verification method for a given API.
                            Generally, there are some basic methods to verify an API response body content:
                            Compare the whole response body content with the expected information
                            This method is suitable for a simple response with static contents. Dynamic information such as date time, increasing ID, etc. will cause trouble in the assertion.
                            Compare each attribute value of the response
                            For those responses in JSON or XML format, it is easy to get the value of a given key or attribute. Hence, this method is helpful when verifying dynamic content, or individual value rather than the whole content.
                            Compare matching with regular expression
                            Together with verifying individual attribute values, this method is used to verify data responses with a specific pattern to handle complex dynamic data.
                            Each verification method has pros and cons, and there is no one-size-fits-all option. You need to choose the solution that best fits your testing project.</p>

                        <h3 id='chapter-4'> Create positive and negative tests ( and exotic tests )</h3>

                        <p>API testing requires both positive and negative tests to ensure that the API is working correctly. Since API testing is considered a type of black-box testing, both types of testings are driven by input and output data. There are a few suggestions for test scenario generation:
                            Positive test
                            Verify that the API receives input and returns the expected output as specified in the requirement.
                            Verify that the response status code is returned as specified in the requirement, whether it returns a 2xx or error code.
                            Specify input with minimum required fields and with maximum fields.
                            Negative test
                            Verify that the API returns an appropriate response when the expected output does not exist.
                            Perform input validation test.
                            Verify the API’s behaviors with different levels of authorization.
                            Exotic test (If Needed)
                            Tricky, very specific check / test.
                            Not supposed to be used very often.
                            Not supposed to be a catch-all :)</p>

                        <li> Live testing process</li>
                        <p>Scheduling API test execution every day while the testing process is live is highly recommended. Since API test execution is fast, stable, and small enough, it is easy to add more tests into the current testing process with minimum risks. This is only possible with automated API testing tools that come with features like:
                            Test scheduling with built-in test commands
                            Integration with test management tools and defect tracking tools
                            Continuous Integration with various leading CI tools
                            Visual log reports generation
                            Once the testing process is completed, you can get the result of those tests every day. If failed tests occur, you can check the outputs and validate issues to have proper solutions.
                        </p>

                        <li>Do not underestimate API automation testing</li>
                        <p>API testing flow is quite simple with three main steps:
                            Send the request with necessary input data
                            Get the response having output data
                            Verify that the response returned as expected in the requirement</p>

                    </ol>

                    <h3 id='chapter-5'> API Testing Principles</h3>

                    <ol>
                        Having a set of standard rules is the best way to ensure the quality of your APIs and their implementations. You can apply these rules during testing, coding, and development, as well as in production.
                        Here are some principles that you should keep in mind:
                        <li>API testing should be a part of your continuous integration and delivery pipeline.</li>
                        <li>API tests should be easy to maintain and write.</li>
                        <li>A well-designed API will make your tests easier to write.</li>
                        <li>You should test at the boundary of your system.</li>
                        <li>Keep your tests small and focused.</li>
                        <li>Make sure your tests are deterministic.</li>
                        <li>Run your tests in parallel for speed.</li>
                        <li>Use the available and freely accessible tools to simplify API testing.</li>
                    </ol>





                    <h3 id='chapter-6'>API Testing Tips</h3>

                    <p>API testing can be a challenge, but regardless of the tools you decide to use here are some tips that can help:
                        Make sure you have a clear understanding of the API before you start testing. Read the documentation and any other available materials. This will help you know what to expect and how the API should work.
                        Use API testing tools: This will give you a better understanding of how the API works and make it easier to find any issues.
                        Test all aspects of the API, including input validation, error handling, and security. These are all important factors in ensuring that your API is working correctly.
                        Keep your tests up-to-date as the API changes. This will help ensure that you catch any new issues that may arise.
                        By following these tips, you can help make sure that your API testing is effective and efficient.</p>


                    <h2>Best Practices for API Endpoint Testing by Response Code Type</h2>
                    <ol>
                        <li>2xx Response Codes (Success)</li>
                        <p> Best Practices:
                            - Verify that the response content aligns with expectations.
                            - Check that the response status is correct.
                            - Example:
                            - Endpoint: `/api/users/123`
                            - Expectations: Status 200, returns user details with ID 123.
                            - Assert that status code is the expected one, the error message is the good one, the body is shaped as expected ( if body awaited ).</p>

                        <li> 201 Response Code (Created)</li>
                        <p>  Best Practices:
                            - Confirm that the created resource is accessible at the URI indicated in the `Location` header.
                            - Example:
                            - Endpoint: `/api/users`
                            - Expectations: Status 201, `Location` header pointing to the newly created user resource.
                            - Assert that status code is the expected one, the error message is the good one, the body is shaped as expected ( if body awaited ).</p>


                        <li> 4xx Response Codes (Client Errors)</li>
                        <p>  Best Practices:
                            - Ensure that errors are appropriately handled with explicit messages.
                            - Check that errors are returned with the appropriate status code.
                            - Example:
                            - Endpoint: `/api/orders/999`
                            - Expectations: Status 404, message "Order not found" if the order with ID 999 does not exist.
                            - Assert that status code is the expected one, the error message is the good one, the body is shaped as expected ( if body awaited )</p>

                        <li>  400 Response Code (Bad Request)</li>
                        <p>   Best Practices:
                            - Verify that the bad request is handled with a detailed message.
                            - Check that mandatory fields are properly validated.
                            - Example:
                            - Endpoint: `/api/products`
                            - Expectations: Status 400, message "The 'name' field is required" if the name field is missing in the product creation request.
                            - Assert that status code is the expected one, the error message is the good one, the body is shaped as expected ( if body awaited )</p>

                        <li> 401 Response Code (Unauthorized)</li>
                        <p>   Best Practices:
                            - Confirm that unauthorized access is correctly blocked.
                            - Check that access attempts requiring authentication return the 401 code.
                            - Example:
                            - Endpoint: `/api/admin`
                            - Expectations: Status 401, message "Unauthorized access" if an unauthenticated user attempts to access an admin resource.
                            - Assert that status code is the expected one, the error message is the good one, the body is shaped as expected ( if body awaited )</p>

                        <li>  403 Response Code (Forbidden)</li>
                        <p>    Best Practices:
                            - Verify that forbidden access is properly managed.
                            - Check that unauthorized users are prevented from accessing restricted resources.
                            - Example:
                            - Endpoint: `/api/orders/123`
                            - Expectations: Status 403, message "Forbidden access: you do not have the necessary permissions" if an unauthorized user tries to access a private order.
                            - Assert that status code is the expected one, the error message is the good one, the body is shaped as expected ( if body awaited )</p>

                        <li>500 Response Code (Server Error)</li>
                        <p>- Best Practices:
                            - Verify that internal errors are correctly handled.
                            - Check that errors are logged for later debugging.
                            - Example:
                            - Endpoint: `/api/orders/123`
                            - Expectations: Status 500, message "Internal server error" in case of an unforeseen error while retrieving the order.
                            - Assert that status code is the expected one, the error message is the good one, the body is shaped as expected ( if body awaited )</p>

                    </ol>

                    <p>The most touch parts of API testing are not either sending requests or receiving the response. They are test data management and verification. It is common that testing a few first APIs such as login, query some resources, etc. is quite simple.
                        The testing task becomes more and more difficult to further APIs. Therefore, the API testing task is easy to be underestimated.
                        At some point in time, you would find yourself in the middle of choosing a good approach for test data and verification methods. It is because the returned data have similar structures, but not the same in a testing project.
                        Furthermore, deciding the right one would be difficult if you should verify the JSON/XML data key by key, or use object mapping to leverage the power of programming language.
                        Considering API automation testing a real development project is highly suggested. It should be structured to be extendable, reusable, and maintainable.</p>

                </div>
            </div>
        </div>
    );
}

export default ApiToolsheet;
