import React, { useEffect, useState } from 'react';

const SubscriptionDetails = () => {
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const response = await fetch('/api/subscription-details'); // Endpoint pour récupérer les détails de l'abonnement et du paiement récurrent depuis le backend
        const data = await response.json();
        setSubscription(data);
      } catch (error) {
        console.error('Error fetching subscription details:', error);
      }
    };

    fetchSubscriptionDetails();
    const interval = setInterval(fetchSubscriptionDetails, 60000); // Rafraîchir les détails toutes les minutes

    return () => clearInterval(interval);
  }, []);

  if (!subscription) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Subscription Details</h2>
      <p>Subscription ID: {subscription.id}</p>
      <p>Renewal Date: {subscription.renewalDate}</p>
      <p>Status: {subscription.status}</p>
      {/* Affichez les autres détails de l'abonnement et du paiement récurrent */}
    </div>
  );
};

export default SubscriptionDetails;
