import axios from 'axios';

// Action types
export const FETCH_LICENSES_REQUEST = 'FETCH_LICENSES_REQUEST';
export const FETCH_LICENSES_SUCCESS = 'FETCH_LICENSES_SUCCESS';
export const FETCH_LICENSES_FAILURE = 'FETCH_LICENSES_FAILURE';
export const CREATE_LICENSE_REQUEST = 'CREATE_LICENSE_REQUEST';
export const CREATE_LICENSE_SUCCESS = 'CREATE_LICENSE_SUCCESS';
export const CREATE_LICENSE_FAILURE = 'CREATE_LICENSE_FAILURE';
export const FETCH_ACTIVE_LICENSES_REQUEST = 'FETCH_ACTIVE_LICENSES_REQUEST';
export const FETCH_ACTIVE_LICENSES_SUCCESS = 'FETCH_ACTIVE_LICENSES_SUCCESS';
export const FETCH_ACTIVE_LICENSES_FAILURE = 'FETCH_ACTIVE_LICENSES_FAILURE';

// Action creators
export const fetchLicensesRequest = () => ({
  type: FETCH_LICENSES_REQUEST
});

export const fetchLicensesSuccess = (licenses) => ({
  type: FETCH_LICENSES_SUCCESS,
  payload: licenses
});

export const fetchLicensesFailure = (error) => ({
  type: FETCH_LICENSES_FAILURE,
  payload: error
});

export const createLicenseRequest = () => ({
  type: CREATE_LICENSE_REQUEST
});

export const createLicenseSuccess = (license) => ({
  type: CREATE_LICENSE_SUCCESS,
  payload: license
});

export const createLicenseFailure = (error) => ({
  type: CREATE_LICENSE_FAILURE,
  payload: error
});

export const fetchActiveLicensesRequest = () => ({
  type: FETCH_ACTIVE_LICENSES_REQUEST
});

export const fetchActiveLicensesSuccess = (licenses) => ({
  type: FETCH_ACTIVE_LICENSES_SUCCESS,
  payload: licenses
});

export const fetchActiveLicensesFailure = (error) => ({
  type: FETCH_ACTIVE_LICENSES_FAILURE,
  payload: error
});

// Thunk action to fetch licenses
export const fetchLicenses = () => {
  return (dispatch) => {
    dispatch(fetchLicensesRequest());
    axios
      .get('/api/licenses')
      .then((response) => {
        dispatch(fetchLicensesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchLicensesFailure(error.message));
      });
  };
};

// Thunk action to create a license
export const createLicense = (licenseId) => {
  return (dispatch) => {
    dispatch(createLicenseRequest());
    axios
      .post('/api/licenses', { licenseId })
      .then((response) => {
        dispatch(createLicenseSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createLicenseFailure(error.message));
      });
  };
};

// Thunk action to fetch active licenses
export const fetchActiveLicenses = () => {
  return (dispatch) => {
    dispatch(fetchActiveLicensesRequest());
    axios
      .get('/api/licenses')
      .then((response) => {
        dispatch(fetchActiveLicensesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchActiveLicensesFailure(error.message));
      });
  };
};