import React from 'react';
import { Link } from 'react-router-dom';
import './CoursesList.css';

const CoursesListPage = ({ cours }) => {
  return (
    <div className='courseList'>
      <h2 className='mangaPanel'>

        Unleash Your Potential in Software Testing and Development Best Practices !
      </h2>
      <img className='robotIcon jello-vertical' src='../../kawaii002Tiny.png' alt='robot' />

      <table className='courseTable'>
        <thead>
          <tr>
            <th>Course name</th>
            <th>Let's go !</th>
            <th>Course Category</th>
          </tr>
        </thead>
        <tbody>

          <tr>

            <td className='courseListItem'>
              <img className='robotIcon jello-vertical' src='../../robot002Tiny.png' alt='robot' />
              Agile Testing Fundamentals: Building Your Agile Testing skills
              <img className='robotIcon jello-vertical' src='../../robot002Tiny.png' alt='robot' />
            </td>

            <td>
              <Link to="/AgileTester" className="button">See the course</Link>
            </td>
            <td>Fundamentals Testing Skills / QA</td>
          </tr>

          <tr>

            <td className='courseListItem'>
              <img className='robotIcon jello-vertical' src='../../robot002Tiny.png' alt='robot' />
              Agile Tester: Advanced Testing Techniques
              <img className='robotIcon jello-vertical' src='../../robot002Tiny.png' alt='robot' />
            </td>

            <td>
              <Link to="/AgileTesterAdvanced" className="button">See the course</Link>
            </td>
            <td>Advanced Testing Skills / QA</td>
          </tr>


          <tr>
            <td className='courseListItem'>
              <img className='robotIcon jello-horizontal' src='../../robot003Tiny.png' alt='robot' />
              TDD ( Test driven development ) for dummies :)
              <img className='robotIcon jello-horizontal' src='../../robot003Tiny.png' alt='robot' />
            </td>
            <td>
              <Link to="/tddCourse" className="button">See the course</Link>
            </td>
            <td>Testing Skills / Devs</td>
          </tr>

          <tr>
            <td className='courseListItem'>
              <img className='robotIcon jello-vertical' src='../../robot004Tiny.png' alt='robot' />
              API Test - Best Practices ( WIP)
              <img className='robotIcon jello-vertical' src='../../robot004Tiny.png' alt='robot' /></td>
            <td>
              <Link to="/apiToolsheet" className="button">See the course</Link>
            </td>
            <td>Advanced Testing Skills / QA / Devs</td>
          </tr>

          <tr>
            <td className='courseListItem'>
              <img className='robotIcon jello-vertical' src='../../robot004Tiny.png' alt='robot' />
              INCOMMING COURSE
              <img className='robotIcon jello-vertical' src='../../robot004Tiny.png' alt='robot' /></td>
            <td>
              <Link to="/AgileTester" className="button">See the course</Link>
            </td>
            <td>Team life</td>
          </tr>

        </tbody>
      </table>
    </div>
  );
};

export default CoursesListPage;
