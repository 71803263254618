import React, { useState, useEffect } from 'react';
import '../App.css';
import { BsArrowUpCircle } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { BiSolidLeftArrow, BiSolidRightArrow } from 'react-icons/bi';
import { TbPlayerTrackNext } from 'react-icons/tb'
import { MdSchool, MdQuestionMark } from 'react-icons/md';
import BackToPage from './BackToPage';


const FormComponent = () => {
    const [formData, setFormData] = useState({
        documentation: '',
        endpoint: '',
        httpMethod: '',
        queryParams: '',
        requestBody: '',
        expectedResponse: [],
        testTypes: [],
        testScenarios: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState('');


    const copyResponseToClipboard = () => {
        const responseContainer = document.getElementById('responseContainer');

        const textToCopy = responseContainer.innerText;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success('Réponse copiée dans le presse-papier :)');
            })
            .catch(error => {
                console.error('Erreur lors de la copie dans le presse-papier :', error);
            });


    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'select-multiple' ? Array.from(e.target.selectedOptions, option => option.value) : value,
        }));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const spinner = document.getElementById('spinner');
        spinner.classList.add('show');

        const button = document.getElementById('callAPIButton');
        button.classList.add('hide');
        const copyButton = document.getElementById('copyButton');
        const btnBackToTop = document.getElementById('btnBackToTop');


        const dataToSend = {
            documentation: formData.documentation,
            endpoint: formData.endpoint,
            httpMethod: formData.httpMethod,
            queryParams: formData.queryParams,
            requestBody: formData.requestBody,
            expectedResponse: formData.expectedResponse,
            testTypes: formData.testTypes,
            testScenarios: formData.testScenarios,
        };

        try {
            const response = await axios.post('/api/generate-response', dataToSend); // Send the form data to the backend endpoint
            const generatedResponse = response.data.response;
            setResponse(generatedResponse);
        } catch (error) {
            toast.error(error.message);
            console.error('Error calling backend API:', error);
        } finally {
            spinner.classList.remove('show');
            copyButton.classList.add('show');
            button.classList.remove('hide');
            btnBackToTop.classList.add('show');
            copyButton.scrollIntoView({ behavior: 'smooth' });
            setIsLoading(false);
        };
    };

    const [selectedField, setSelectedField] = useState(null);
    const [isBubbleVisible, setIsBubbleVisible] = useState(false);
    const [isBubbleTutoVisible, setIsBubbleTutoVisible] = useState(false);
    const [bubbleTextIndex, setBubbleTextIndex] = useState(0);
    const bubbleTexts = [
        "Hello there!",
        "I'm AI-ducator. I will teach you how to use this cool tool !",
        "Click on the robot head above each fields to learn how to use each field !"
    ];

    const handleBubbleClick = () => {
        const educate = document.getElementById('educate');
        const clickMe = document.getElementById('clickMe');
        const arrow = document.getElementById('arrow');

        educate.classList.add('hide');
        clickMe.classList.add('hide');
        arrow.classList.add('show');
        setIsBubbleVisible(!isBubbleVisible);

    };

    const handleArrowClick = () => {
        setBubbleTextIndex((bubbleTextIndex + 1) % bubbleTexts.length);
    };

    const [showBackToTop, setShowBackToTop] = useState(false);

    // Function to handle scroll event and toggle the visibility of the Back to Top button
    const handleScroll = () => {
        const responseContainer = document.getElementById('responseContainer');
        if (responseContainer) {
            setShowBackToTop(window.scrollY > 0);
        }
    };

    const [tutorialMessages, setTutorialMessages] = useState({
        documentation: 'The "Documentation" field allows you to describe in detail what the endpoint you want to test does. The more details you add, the more relevant tests I can generate.',
        endpoint: 'In the "Endpoint" field, you can indicate the route of your API to make test generation as efficient and immediately importable as possible.',
        httpMethod: 'Message tutoriel pour la méthode HTTP',
        queryParams: "If your endpoint expects parameters, please specify them here; otherwise, leave this field empty.",
        requestBody: "If your endpoint expects a specific body, please add it here; otherwise, leave this field empty.",
        expectedResponse: "Select the error code(s) you want to test, and I will generate the tests based on your choices.",
        testTypes: 'In "Test Types" you can indicate whether you want to focus the test generation on, for example, all passing cases, security tests, or a mix of everything with "All types here".',
        testScenarios: 'You can specify test scenarios to help me better orient the test generation.\nExample: "a user with insufficient rights attempts to log in".',
    });

    const handleTutorialClick = (field) => {

        setIsBubbleTutoVisible(!isBubbleTutoVisible);


        if (selectedField) {

            setSelectedField(null);
        } else {

            // Sinon, afficher la bulle pour le champ sélectionné
            setSelectedField(field);
        }
    };


    // Add scroll event listener when the component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <ToastContainer position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />

            {/* Add the bubble */}
            <div className='terminator'>
                <div className="bubble-icon bounce-in-left" onClick={handleBubbleClick}>
                    <div id='clickMe' className='bubbleIconTxt bounce-in-left'>Click me !</div>
                    <MdSchool id='educate' className='bubbleIcon bounce-in-left' />
                    <BiSolidRightArrow id='arrow' className='bubbleIcon bounce-in-left' />
                </div>


                {isBubbleVisible && (
                    <div className="bubble-container">
                        <div className="bubble">
                            <p className="dialogue-text">{bubbleTexts[bubbleTextIndex]}</p>
                            <div className="arrow-btn" onClick={handleArrowClick}>
                                <TbPlayerTrackNext />
                            </div>
                        </div>
                    </div>
                )}
                {isBubbleTutoVisible && (
                    <div className="bubble-container">
                        <div className="bubble">
                            <p className="dialogue-text2">
                                {selectedField && tutorialMessages[selectedField]}
                            </p>
                        </div>
                    </div>
                )}

                <div class="moving-object-info001 bounce-in-left">
                    <img
                        src="../../WALL_AI_001.png"
                        alt="Wall-AI Robot"
                    /></div>
            </div>
            {/* End the bubble */}

            <form className='form001 slide-in-bottom' onSubmit={handleSubmit}>
                <label className='labelColor'>
                    <img src="../../robot002Tiny.png"
                        alt="TutorialRobot_documentation"
                        onClick={() => handleTutorialClick('documentation')}
                        className='headBot jello-horizontal' />
                    Quick endpoint context, documentation:

                </label>
                <textarea
                    rows="3" cols="42"
                    type="text"
                    name="documentation"
                    value={formData.documentation}
                    onChange={handleChange}
                    required
                />
                {/* <div className='tutorial-icon2' onClick={() => handleTutorialClick('documentation')}><BiSolidLeftArrow /><FaQuestion /></div>*/}
                <br />
                <label className='labelColor'>
                    <img src="../../robot002Tiny.png"
                        alt="TutorialRobot_testTypes"
                        onClick={() => handleTutorialClick('testTypes')}
                        className='headBot jello-horizontal' />
                    Tests types ( Multi-selectable ):
                </label>

                <select
                    className='testTypes'
                    name="testTypes"
                    value={formData.testTypes}
                    onChange={handleChange}
                    multiple
                    required
                    defaultValue={"all_possible_options"} >
                    <option value="All passing cases">All passing cases</option>
                    <option value="All error cases">All error cases</option>
                    <option value="Security tests">Security tests</option>
                    <option value="Robustness tests">Robustness tests</option>
                    <option value="Performance tests">Performance tests</option>
                    <option value="all_possible_options">All possible tests types</option>
                </select>
                {/* <div className='tutorial-icon3' onClick={() => handleTutorialClick('testTypes')}><BiSolidLeftArrow /><FaQuestion /></div>*/}
                <br />
                <label className='labelColor'>
                    <img src="../../robot002Tiny.png"
                        alt="TutorialRobot_endpoint" onClick={() => handleTutorialClick('endpoint')} className='headBot jello-horizontal' />  Endpoint:
                </label>
                <input
                    type="text"
                    name="endpoint"
                    className='endpointInput'
                    value={formData.endpoint}
                    onChange={handleChange}
                    required
                />
                 <div className='tutorial-icon4' onClick={() => handleTutorialClick('endpoint')}></div>
                <br />
                <label className='labelColor'>
                <img src="../../robot002Tiny.png"  alt="TutorialRobot_endpoint" onClick={() => handleTutorialClick('httpMethod')} className='headBot jello-horizontal' />  
                    HTTP Method:
                </label>
                <select
                    name="httpMethod"
                    value={formData.httpMethod}
                    onChange={handleChange}
                    required
                    defaultValue="HTTP Method">
                    <option value="" disabled >HTTP Method</option>
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                    <option value="PATCH">PATCH</option>
                    <option value="OPTION">OPTION</option>
                    <option value="DELETE">DELETE</option>
                </select>

    <br />
                <label className='labelColor'>
                    <img src="../../robot002Tiny.png"
                        alt="TutorialRobot_queryParams" onClick={() => handleTutorialClick('queryParams')} className='headBot jello-horizontal' />   Query parameters:
                </label>
                <textarea
                    rows="2" cols="42"
                    type="text"
                    name="queryParams"
                    value={formData.queryParams}
                    onChange={handleChange}
                />
                {/* <div className='tutorial-icon5' onClick={() => handleTutorialClick('queryParams')}><BiSolidLeftArrow /><FaQuestion /></div>*/}
                <br />
                <label className='labelColor'>
                    <img src="../../robot002Tiny.png"
                        alt="TutorialRobot_requestBody" onClick={() => handleTutorialClick('requestBody')} className='headBot jello-horizontal' />   Request body:  </label>
                <textarea
                    rows="3" cols="42"
                    type="text"
                    name="requestBody"
                    value={formData.requestBody}
                    onChange={handleChange}
                />
                {/*<div className='tutorial-icon6' onClick={() => handleTutorialClick('requestBody')}><BiSolidLeftArrow /><FaQuestion /></div>*/}
                <br />
                <label className='labelColor'>
                    <img src="../../robot002Tiny.png"
                        alt="TutorialRobot_expectedResponse" onClick={() => handleTutorialClick('expectedResponse')} className='headBot jello-horizontal' />   Expected response ( Multi-selectable ):
                </label>

                <select
                    className='expectedResponse'
                    name="expectedResponse"
                    value={formData.expectedResponse}
                    onChange={handleChange}
                    multiple
                    required
                    defaultValue={"all_possible_options"} >
                    <option value="200">200</option>
                    <option value="201">201</option>
                    <option value="400">400</option>
                    <option value="403">403</option>
                    <option value="404">404</option>
                    <option value="422">422</option>
                    <option value="500">500</option>
                    <option value="all_possible_options" selected>all codes here</option>
                </select>
                {/* <div className='tutorial-icon7' onClick={() => handleTutorialClick('expectedResponse')}> <BiSolidLeftArrow /><FaQuestion /></div>*/}
                <br />
                <label className='labelColor'>
                <img src="../../robot002Tiny.png"
                        alt="TutorialRobot_testScenarios" className='headBot jello-horizontal' onClick={() => handleTutorialClick('testScenarios')} />   Expected test scenarios: 
                </label>
                <textarea
                    rows="3" cols="35"
                    type="text"
                    name="testScenarios"
                    value={formData.testScenarios}
                    onChange={handleChange}
                />
                {/* <div className='tutorial-icon8' onClick={() => handleTutorialClick('testScenarios')} ><BiSolidLeftArrow /><FaQuestion /></div>*/}
                <br />
                <button type="submit" id='callAPIButton' className='callAPIButton' disabled={isLoading}>
                    {isLoading ? 'Generating...' : 'Generate'}
                </button>
            </form>
            <br />
            <div id="spinner"></div>
            {isLoading && <div id="spinner"></div>}
            {!isLoading && response && (
                <pre id="responseContainer">{response}
                </pre>
            )}
            <h3 className='importResponseTxt'> Copy the JSON code and paste it into the "Import" section of your Postman application, do not forget to replace the variables if the generated ones are examples.
            </h3>
            <div>
                <button id="copyButton" className="copyButton" onClick={copyResponseToClipboard}>Copy to clipboard</button>
                <button id="downloadBtn" className="downloadBtn">Download JSON</button>
                <button
                    id="btnBackToTop"
                    className='btnBackToTop'
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    style={{ display: showBackToTop ? 'block' : 'none' }}
                >
                    <BsArrowUpCircle />
                </button>
            </div>
        </div>

    );
};

export default FormComponent;
