import React, { useState } from 'react';
import { equals, and, or } from 'dynamodb-expressions';

function DynamoDBPage() {
  const [tableName, setTableName] = useState('');
  const [keyCondition, setKeyCondition] = useState({});
  const [filterCondition, setFilterCondition] = useState({});
  const [dynamoDBQuery, setDynamoDBQuery] = useState('');

  const handleTableNameChange = (e) => {
    setTableName(e.target.value);
  };

  const handleKeyConditionChange = (e) => {
    const [key, value] = e.target.value.split(':');
    setKeyCondition({ ...keyCondition, [key]: value });
  };

  const handleFilterConditionChange = (e) => {
    const [key, value] = e.target.value.split(':');
    setFilterCondition({ ...filterCondition, [key]: value });
  };

  const buildDynamoDBQuery = () => {
    // Construction de l'expression de condition clé
    const keyExpressions = Object.entries(keyCondition).map(([key, value]) =>
      equals(key, value)
    );

    // Construction de l'expression de condition de filtre
    const filterExpressions = Object.entries(filterCondition).map(([key, value]) =>
      equals(key, value)
    );

    // Combinaison des expressions de condition clé et de filtre
    const queryExpression = and(...keyExpressions, ...filterExpressions);

    // Construction de la requête DynamoDB finale
    const query = {
      TableName: tableName,
      KeyConditionExpression: queryExpression.serialize(),
    };

    setDynamoDBQuery(JSON.stringify(query, null, 2));
  };

  return (
    <div className="form002 dynamodb-query-builder">
      <h1>DynamoDB Query Builder</h1>
      <label>
        Nom de la table :
        <input type="text" value={tableName} onChange={handleTableNameChange} />
      </label>
      <br />
      <label>
        Condition clé (clé:valeur) :
        <input type="text" placeholder="clé:valeur" onChange={handleKeyConditionChange} />
      </label>
      <button onClick={buildDynamoDBQuery}>Ajouter une condition clé</button>
      <br />
      <label>
        Condition de filtre (clé:valeur) :
        <input type="text" placeholder="clé:valeur" onChange={handleFilterConditionChange} />
      </label>
      <button onClick={buildDynamoDBQuery}>Ajouter une condition de filtre</button>
      <br />
      <button onClick={buildDynamoDBQuery}>Construire la requête DynamoDB</button>
      {dynamoDBQuery && (
        <div className="result">
          <h2>Requête DynamoDB générée :</h2>
          <pre>{dynamoDBQuery}</pre>
        </div>
      )}
    </div>
  );
}

export default DynamoDBPage;
