import React from 'react';
import ScrollToTopButton from './ScrollToTopBtn';
import BackToPage from './BackToPage';

const TDDCourse = () => {


    const codeSnippet = `
      // Step 1: Writing the Test (Red)
      function add(a, b) {
        return a + b;
      }
      
      test('Addition', () => {
        const result = add(2, 3);
        expect(result).toBe(5);
      });
      
      // Step 2: Implementing the Minimal Code (Green)
      function add(a, b) {
        return a + b;
      }
      
      // Step 3: Refactoring
      function add(a, b) {
        // The function is already implemented correctly
        return a + b;
      }
      `;

    const codeSnippet2 = `
      import { render, screen } from '@testing-library/react';
      import App from './App';
      
      test('renders learn react link', () => {
        render(<App />);
        const linkElement = screen.getByText(/learn react/i);
        expect(linkElement).toBeInTheDocument();
      });
      `;

    const codeSnippet3 = `
      function add(a, b) {
        return a + b;
      }
      `;

    const codeSnippet4 = `
      function add(a, b) {
        // The function is already implemented correctly
        return a + b;
      }
      `;

    const codeSnippet5 = `
     
test('Addition', () => {
    const result = add(2, 3);
    expect(result).toBe(5); // The test fails initially
  });
      `;

    const codeSnippet6 = `
      function add(a, b) {
        return a + b;
      }
      `;

    const codeSnippet7 = `
      function add(a, b) {
        // The function is already implemented correctly
        return a + b;
      }
      `;



    return (
        <div> <BackToPage />
            <div className="course-details">
                <ScrollToTopButton />
                <h2><img className='robotIcon jello-vertical' src='../../robot003Tiny.png' alt='robot' /> Test Driven Development (TDD) <img className='robotIcon jello-vertical' src='../../robot003Tiny.png' alt='robot' /></h2>
                <div className="meta">
                    <img className='robotIcon jello-vertical' src='../../kawaii001Tiny.png' alt='robot' />
                    <div className="author">Auteur: JC Jamet</div>
                    <div className="duration">Reading time: 15 min </div>
                    <img className='robotIcon jello-vertical' src='../../star001.png' alt='robot' />
                </div>
                <h3>Table of Contents</h3>
                <ul>
                    <li><a href="#chapter-1">Chapter 1: Introduction to Test Driven Development (TDD)</a></li>
                    <li><a href="#chapter-2">Chapter 2: The Steps of Test Driven Development (TDD)</a></li>
                    <li><a href="#chapter-3">Chapter 3: The Red-Green-Refactor Cycle</a></li>
                    <li><a href="#chapter-4">Chapter 4: Implementing TDD in a Project</a></li>
                    <li><a href="#chapter-5">Chapter 5: Conclusion</a></li>
                </ul>


                ____________________


                <div className="chapter" id='chapter-1'>
                    <h3 id='chapter-1'>Chapter 1: What is Test Driven Development (TDD) ?</h3>
                    <p>
                        Test Driven Development is a software development approach where automated tests are written before coding the functionality. The development process follows a short iterative loop called "Red-Green-Refactor" (RGR).
                    </p>


                    <h4>Section 1.1: What is TDD?</h4>
                    <p>
                        Test Driven Development (TDD) is an approach where tests are written before implementing the code. The process follows three steps:
                        <br />
                        1. <strong>Red</strong>: Write a test that fails because the code hasn't been implemented yet.
                        <br />
                        2. <strong>Green</strong>: Write the minimal code to make the test pass.
                        <br />
                        3. <strong>Refactor</strong>: Optimize the code while maintaining successful tests.
                    </p>

                    <h4>Section 1.2: Benefits of Using TDD</h4>
                    <p>
                        TDD offers several benefits:
                        <br />
                        - <strong>Code Quality</strong>: Automated tests ensure that the code works correctly and prevents regressions.
                        <br />
                        - <strong>Maintainability</strong>: Tests serve as documentation for the expected behavior of the code, facilitating long-term maintenance.
                        <br />
                        - <strong>Simple Design</strong>: Tests encourage a simple and modular design by focusing on usage.
                        <br />
                        - <strong>Confidence</strong>: Developers gain confidence in their changes as tests verify the code's correctness.
                        <br />
                        - <strong>Speed</strong>: TDD improves development speed through better quality and minimized regressions.
                    </p>

                    <h4>Section 1.3: Implementing the Red-Green-Refactor Cycle</h4>
                    <p>
                        The Red-Green-Refactor process is repeated for each new feature or code modification. Make sure to write tests that cover different scenarios and edge cases to achieve adequate test coverage.
                    </p>

                    <h4>Complete Red-Green-Refactor Cycle Example:</h4>
                    <pre>
                        <code dangerouslySetInnerHTML={{ __html: codeSnippet }} />
                    </pre>

                    <p>
                        The Red-Green-Refactor cycle ensures iterative development, correct functioning from the start, and a quality-focused approach to coding.
                    </p>

                </div>

                ____________________


                <div className="chapter" id='chapter-2'>
                    <h3 id='chapter-2'>Chapter 2: The Steps of Test Driven Development (TDD)</h3>
                    <p>
                        The Test Driven Development (TDD) process consists of three main steps that are repeated for each feature or code modification.
                    </p>

                    <h4>Step 1: Writing the Test (Red)</h4>
                    <p>
                        In this step, you write a test that fails because the corresponding code hasn't been implemented yet. The test should check the desired behavior of the code.
                    </p>
                    <pre>
                        <code dangerouslySetInnerHTML={{ __html: codeSnippet2 }} />
                    </pre>

                    <h4>Step 2: Implementing the Minimal Code (Green)</h4>
                    <p>
                        Here, you implement the minimal code required to make the test pass successfully. The code doesn't need to be perfect at this stage, as the goal is to pass the test.
                    </p>
                    <pre>
                        <code dangerouslySetInnerHTML={{ __html: codeSnippet3 }} />

                    </pre>

                    <h4>Step 3: Refactoring</h4>
                    <p>
                        After the test passes, you can refactor the code to improve its quality, maintainability, and performance. Refactoring should not change the behavior of the code; it simply makes it better.
                    </p>
                    <pre>
                        <code dangerouslySetInnerHTML={{ __html: codeSnippet4 }} />
                    </pre>

                    <p>
                        By following these steps for each feature, you ensure that your code is thoroughly tested, performs correctly, and remains maintainable over time.
                    </p>

                </div>

                ____________________


                <div className="chapter" id='chapter-3'>
                    <h3 id='chapter-3'>Chapter 3: The Red-Green-Refactor Cycle</h3>
                    <p>
                        The Red-Green-Refactor cycle is a fundamental process in Test Driven Development (TDD). It consists of three iterative steps that ensure code quality and functionality.
                    </p>

                    <h4>Step 1: Red (Writing the Test)</h4>
                    <p>
                        In the first step, you write a test that defines the desired behavior of the code. The test should fail initially because the code hasn't been implemented yet.
                    </p>
                    <pre>
                        <code dangerouslySetInnerHTML={{ __html: codeSnippet5 }} />
                    </pre>

                    <h4>Step 2: Green (Implementing the Minimal Code)</h4>
                    <p>
                        In the second step, you implement the minimal code required to make the test pass successfully. The focus is on getting the test to pass, not writing perfect code.
                    </p>
                    <pre>

                        <code dangerouslySetInnerHTML={{ __html: codeSnippet6 }} />
                    </pre>

                    <h4>Step 3: Refactor</h4>
                    <p>
                        After the test passes, you can refactor the code to improve its design and maintainability. Refactoring ensures that the code remains clean and efficient without changing its behavior.
                    </p>
                    <pre>
                        <code dangerouslySetInnerHTML={{ __html: codeSnippet7 }} />

                    </pre>

                    <h4>Advantages of the Red-Green-Refactor Cycle</h4>
                    <p>
                        The Red-Green-Refactor cycle promotes iterative development, correct functioning from the start, and a quality-focused approach to coding. It allows developers to build reliable and maintainable software through continuous testing and improvement.
                    </p>

                </div>

                ____________________


                <div className="chapter" id='chapter-4'>
                    <h3 id='chapter-4'>Chapter 4: Implementing TDD in a Project</h3>
                    <p>
                        Implementing Test Driven Development (TDD) in a project involves selecting the right testing tools and adopting effective testing strategies. Let's explore the key aspects of implementing TDD.
                    </p>

                    <h4>Step 1: Choosing Test Tools</h4>
                    <p>
                        To begin, select appropriate testing tools based on your project's programming language and requirements. Popular testing frameworks for TDD include:
                    </p>
                    <ul>
                        <li>JavaScript: <pre>jest, mocha, chai</pre></li>
                        <li>Python: <pre>unittest, pytest</pre></li>
                        <li>Java: <pre>JUnit</pre></li>
                        <li>C#: <pre>NUnit, xUnit</pre></li>
                        <li>PHP: <pre>PhpUnit</pre></li>
                    </ul>

                    <h4>Step 2: Testing Strategies</h4>
                    <p>
                        Adopting effective testing strategies is crucial for successful TDD implementation. Here are some best practices to consider:
                    </p>
                    <ul>
                        <li><strong>Test Edge Cases</strong>: Ensure your tests cover edge cases and special scenarios to guarantee code robustness.</li>
                        <li><strong>Test Behavior, Not Implementation</strong>: Focus on verifying the expected behavior of the code, not its internal implementation.</li>
                        <li><strong>Keep Tests Fast and Isolated</strong>: Write tests that execute quickly and independently to avoid unwanted dependencies.</li>
                        <li><strong>Refactor with Confidence</strong>: Leverage tests to ensure changes don't break existing code, allowing you to refactor with confidence.</li>
                    </ul>

                    <h4>Conclusion</h4>
                    <p>
                        By adopting Test Driven Development and following these strategies, you can enhance code quality, maintainability, and development efficiency. Embrace TDD as an integral part of your software development process to build robust and reliable applications.
                    </p>

                </div>

                ____________________


                <div className="chapter" id='chapter-5'>
                    <h3 id='chapter-5'>Chapter 5: Conclusion</h3>
                    <p>
                        Test Driven Development (TDD) is a powerful software development approach that promotes code quality, confidence, and maintainability. By writing automated tests before coding, developers gain a better understanding of requirements and ensure that the code works correctly from the start. Although TDD requires an initial investment, the long-term benefits are well worth it.
                    </p>

                    <h4>Benefits of TDD</h4>
                    <p>
                        TDD offers several advantages, including:
                    </p>
                    <ul>
                        <li>Improved Code Quality: Automated tests ensure the code works correctly and prevent regressions.</li>
                        <li>Maintainability: Tests serve as documentation and facilitate long-term code maintenance.</li>
                        <li>Simple Design: TDD encourages a simple and modular design based on usage.</li>
                        <li>Confidence: Developers gain confidence in their changes as tests verify correctness.</li>
                        <li>Speed: TDD improves development speed through better quality and minimized regressions.</li>
                    </ul>

                    <h4>Regular Practice and Growth</h4>
                    <p>
                        Regularly practicing TDD leads to improved skills and a better sense of code quality. Embrace TDD in your projects to enhance customer satisfaction and software stability. With time and experience, TDD becomes a natural part of your development process.
                    </p>

                    <h4>Final Thoughts</h4>
                    <p>
                        Remember, Test Driven Development is not only about tests, but also about guiding the design and improving the software development process. By writing tests first, you ensure that the code meets the desired behavior, leading to a robust and reliable application.
                    </p>

                </div>

                ____________________

                <div className='happyRobot'>
                    <h4>Congratulations, you have now explored all chapters of this course on TDD !</h4>

                    <div className='happyRobot2'>  <img className='happyRobot001 vibrate-1' src='../../happy_robot.png' alt='congrat_robot' />
                        <img className='iconKawaii puff-out-center' src='../../bubble001.png' alt='congrat_bubble' />
                        <img className='iconKawaii  puff-out-bl' src='../../star001.png' alt='congrat_star' />

                        <img className='iconKawaii puff-out-center2' src='../../heart001.png' alt='congrat_heart' />
                        <img className='iconKawaii puff-out-center3' src='../../bubble001.png' alt='congrat_bubble' />
                        <img className='iconKawaii puff-out-center2' src='../../heart001.png' alt='congrat_heart' />
                        <img className='iconKawaii  puff-out-br' src='../../star001.png' alt='congrat_star' />
                        <img className='iconKawaii puff-out-center2' src='../../bubble001.png' alt='congrat_bubble' />
                        <img className='happyRobot001 vibrate-1' src='../../happy_robotReverse.png' alt='congrat_robot' /></div>
                </div>
            </div >
        </div>
    );
};

export default TDDCourse;
