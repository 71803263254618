import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

function Base64Tool() {
    const [inputText, setInputText] = useState('');
    const [encodedText, setEncodedText] = useState('');
    const [decodedText, setDecodedText] = useState('');

    const handleInputChange = (e) => {
        setInputText(e.target.value);
    };

    const copyResponseToClipboard = (elementId) => {
        const responseContainer = document.getElementById(elementId);

        const textToCopy = responseContainer.innerText;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success('Response copied to clipboard :)');
            })
            .catch(error => {
                toast.error('Error copying to clipboard :', error);
            });
    };

    const handleEncodeBase64 = async () => {
        try {
            const response = await fetch('/api/encode-base64', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ inputText }),
            });
            if (response.ok) {
                const data = await response.json();
                setEncodedText(data.encodedText);
            } else {
                console.error('Erreur lors de la requête au serveur');
            }
        } catch (error) {
            console.error('Erreur lors de la communication avec le serveur', error);
        }
    };

    const handleDecodeBase64 = async () => {
        try {
            const response = await fetch('/api/decode-base64', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ inputText }),
            });
            if (response.ok) {
                const data = await response.json();
                setDecodedText(data.decodedText);
            } else {
                console.error('Erreur lors de la requête au serveur');
            }
        } catch (error) {
            console.error('Erreur lors de la communication avec le serveur', error);
        }
    };

    return (
        <div className="form002">
        <ToastContainer position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        <h1>Encode / Decode Base64</h1>
        <textarea
            placeholder="Entrez le texte ici..."
            value={inputText}
            onChange={handleInputChange}
        ></textarea>
        <br />
        <button className="btnBase64" onClick={handleEncodeBase64}>Encode Base64</button>
        <br />
        <button className="btnBase64" onClick={handleDecodeBase64}>Decode Base64</button>
        
        {encodedText && (
            <div className="result">
                <h2>Encoded result :</h2>
                <pre className="overflow-pre" id="encodedResultTxt">{encodedText}</pre>
                <br />
                <button className="btnBase64"  onClick={() => copyResponseToClipboard('encodedResultTxt')}>Copy to clipboard</button>
            </div>
        )}
        {decodedText && (
            <div className="result">
                <h2>Decoded result :</h2>
                <pre className="overflow-pre" id="decodedResultTxt">{decodedText}</pre>
                <br />
                <button className="btnBase64" onClick={() => copyResponseToClipboard('decodedResultTxt')}>Copy to clipboard</button>
            </div>
        )}
    </div>
    );
}

export default Base64Tool;
