import axios from 'axios';
//const [validSubscription, setIsValidSubscription] = useState(false);
export const fetchSubscriptionValidity = (userInfos) => async (dispatch) => {
    try {
        //console.log("COUCOU", userInfos._id);
        const response = await axios.get(`/api/isValidSubscription/${userInfos._id}`, {
            headers: { Authorization: `Bearer ${userInfos?.token}` },
        });
        localStorage.setItem('subscription', JSON.stringify(response.data));
        //dispatch(setIsValidSubscription(response.data.isValid));

        const jsonDate = response.data.renewDate; // Assurez-vous de récupérer la date à partir de la réponse
        const date = new Date(jsonDate);
        const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;

        console.log(response.data.renewDate); // Résultat : "13/7/2023 15:30:45"
        if (response.data.renewDate == undefined) {
           // console.log("response.data.message", response.data.message)
            localStorage.setItem('subscriptionDate', JSON.stringify(response.data.message));
            dispatch(setIsFormattedDate(response.data.message));
        } else {
            localStorage.setItem('subscriptionDate', JSON.stringify(formattedDate));
            dispatch(setIsFormattedDate(formattedDate));
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Le token est expiré ou absent
            localStorage.clear(); // Videz le localStorage
            window.location.replace('/login');
        }
        console.error('Une erreur est survenue lors de la vérification de l\'abonnement', error);
        // Vous pouvez également dispatch une action d'erreur ici si nécessaire
    }
};

export const setIsFormattedDate = (formattedDate) => ({
    type: 'SET_FORMATTED_DATE',
    payload: formattedDate,
});
