import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const SqlPerfectorPage = () => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const copyResponseToClipboard = () => {
    const responseContainer = document.getElementById('responseContainer');

    const textToCopy = responseContainer.innerText;

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        toast.success('Réponse copiée dans le presse-papier :)');
      })
      .catch(error => {
        console.error('Erreur lors de la copie dans le presse-papier :', error);
      });
  };



  const reloadAfterDelay = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set isLoading to true before making the API call


    if (query.length < 12) {
      toast.error('Query must be at least 12 characters long');
      reloadAfterDelay();
    } else {
      const spinner = document.getElementById('spinner');
      spinner.classList.add('show');
      const button = document.getElementById('callAPIButton');
      button.classList.add('hide');
      const copyButton = document.getElementById('copyButton');
      
      //const btnBackToTop = document.getElementById('btnBackToTop');

      try {
        const response = await axios.post('/api/sqlPerfector', { query });
        //console.log(response.data.response);
        setResponse(response.data.response);
      } catch (error) {
        toast.error(error);
        console.error('Error calling ChatGPT API:', error);
      } finally {
        spinner.classList.remove('show');
        copyButton.classList.add('show');
        //copyButton.classList.add('show');
        button.classList.remove('hide');
        //btnBackToTop.classList.add('show');
        //copyButton.scrollIntoView({ behavior: 'smooth' });
        setIsLoading(false); // Set isLoading to false after the API call is completed
      }
    }
  };

  return (
    <div>
      <ToastContainer position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <h2>SQL and NoSQL Query Perfector</h2>
      <form className='form003SQL' onSubmit={handleSubmit}>
        <h2> Enter your SQL or NoSQL query:</h2>
        <label className='labelColor'>

          <textarea
            value={query}
            onChange={handleQueryChange}
            rows={14}
            cols={50}
            required
            minLength="8"
            maxLength="512"
            placeholder='
            db.users.find({ age: { $gte: 18 } });
            OR
            SELECT * FROM Toto t
            INNER JOIN Lala l ON t.totoID = l.totoID;'
          />
        </label>
        <br />
        <button type="submit" id='callAPIButton' className='callAPIButton' disabled={isLoading}>Get Perfected Query</button>
      </form>
      <div id="spinner"></div>
      {isLoading && <div className="spinner"></div>}
      {response && (
        <div>

          {!isLoading && response && (
            <div>
              <h3 className='sqlH3'>Perfected Query:</h3>
              <pre id="responseContainer">{response}</pre>

            </div>
          )}
          
        </div>
      )}
      <button id="copyButton" className="copyButton" onClick={copyResponseToClipboard}>Copy to clipboard</button>
    </div>
  );
};

export default SqlPerfectorPage;
