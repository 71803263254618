import React, { useState } from 'react';

function CancelSubscriptionButton(subscriptionId, userId) {
    const [status, setStatus] = useState('');

    const handleCancelSubscription = async () => {
        setStatus('Cancelling subscription...');
        try {
            const response = await fetch('/api/cancel-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ subscriptionId, userId })
            });
            if (response.ok) {
                setStatus('Subscription cancelled successfully :( Sad to see you leave...');
            } else {
                setStatus('An error occurred while cancelling the subscription' + response.data);
            }
        } catch (err) {
            console.error(err);
            setStatus('An error occurred while cancelling the subscription' + err.message);
        }
    };

    return (
        <div>
            <button className='cancelBtn' onClick={handleCancelSubscription}>Cancel Subscription</button>
            <p>{status}</p>
        </div>
    );
}

export default CancelSubscriptionButton;
